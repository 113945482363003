.enviromentimpact.inner-banner .home-banner-texter h1 span.text-orange {
    font-size: 116px;
    line-height: 80px;
}

[data-theme='light'] .enviromentimpact.inner-banner .background-image img {
    z-index: 0;
}

[data-theme='light'] .enviromentimpact.inner-banner .home-banner-texter p,
[data-theme='light'] .enviromentimpact.inner-banner .home-banner-texter h1 span.text-white {
    color: #000 !important;
}

[data-theme='light'] .inner-banner.enviromentimpact {
    /* background-color: #000; */
}

[data-theme='light'] .inner-banner.enviromentimpact .background-image::before{
    display: none;
}
[data-theme='light'] .inner-banner.enviromentimpact{
    /* background: transparent !important; */
}
[data-theme='light'] .inner-banner.enviromentimpact::before{
    display: none;
}

.enviromentimpact.inner-banner .background-image img {
    z-index: -1;
}

.enviromentimpact.inner-banner .home-banner-texter h1 span.text-white {
    font-size: 52px;
    line-height: 80px;
}

.enviromentimpact.inner-banner .home-banner-texter p {
    color: var(--paragraphcolor);
}

.enviromentimpact .background-image::before {
    z-index: -1 !important;
}

@media (max-width: 1366px) {
    .enviromentimpact.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 90px;
        line-height: 70px;
    }

    .enviromentimpact.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 42px;
    }
}

@media (max-width: 991px) {
    .enviromentimpact.inner-banner .home-banner-texter p br {
        display: none;
    }

    .enviromentimpact.inner-banner .home-banner-texter {
        padding-top: 0 !important;
    }
}

@media (max-width: 767px) {
    .enviromentimpact.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 70px;
        line-height: 50px;
    }

    .enviromentimpact.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 32px;
    }
}