.policy-page a{
    color: #FF7900;
}
.policy-page h4, .policy-page h5{
    font-weight: normal;
    color: var(--white);
}
.policy-page h5 strong{
    font-weight: 900;
}
.policy-page p{
font-size: 16px;
/* text-align: justify; */
}

.policy-page h4 {
    font-size: 20px;
    line-height: 26px;
}