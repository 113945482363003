.laptopvideoslide.img-frame video{
    top: 20px;
    width: calc(100% - 25%);
    height: calc(100% - 0px);
    z-index: 0;
}

.laptopvideoslide.img-frame:before {
    display: none;
}

.img-frame img {
    width: 100%;
}


@media (max-width: 1366px) {
    .laptopvideoslide.img-frame .image-slide {
        top: 30px;
    }
}
@media (max-width: 767px) {
    .laptopvideoslide.img-frame .image-slide {
        top: 25px;
    }
}

@media (max-width: 575px) {
    .laptopvideoslide.img-frame .image-slide {
        top: 15px;
    }
}