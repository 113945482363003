@media (max-width: 1366.99px) {
    .heading-style h4 {
        font-size: 24px;
    }

    .heading-style h5 {
        font-size: 28px;
    }

    .common-section-all {
        padding: 70px 0;
    }

    .inner-banner .home-banner-texter {
        padding: 50px 0;
    }

    .heading-style h4 br,
    .business-customer ul li br {
        display: none;
    }
}

@media (max-width: 1200.99px) {
    .heading-style h4 {
        font-size: 22px;
        margin: 10px 0;
    }

    .heading-style h5 {
        font-size: 26px;
    }

    .common-section-all {
        padding: 60px 0;
    }

    .inner-banner .home-banner-texter {
        padding: 40px 0;
    }
}

@media (min-width: 1024.99px) {

    body .d-lg-block,
    .navbar-brand {
        display: none;
    }

    .mob-block {
        display: none !important;
    }

    .desk-block {
        display: block !important;
    }

    .btn--primary span {
        font-size: 16px;
        line-height: normal;
    }

}

@media (max-width: 991.99px) {
    .home-banner-texter p {
        margin-bottom: 0;
    }

    .home-banner-texter p {
        font-size: 16px;
    }

    ul li {
        font-size: 14px;
    }

    .common-section-all {
        padding: 50px 0;
    }

    .heading-style h5 {
        margin-bottom: 0;

    }

    .heading-style h4 {
        margin: 5px 0;
        font-size: 22px;
        line-height: 35px;
    }

    .heading-style br {
        display: none;
    }

    .business-customer h3 {
        margin-bottom: 23px;
        padding-bottom: 23px;
    }

    .slick-arrow {
        width: 35px;
        height: 35px;
    }

    body {
        font-size: 14px;
    }
    .btn--primary--outline, .btn--primary, .btn--secondary{
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .home-banner {
        overflow: hidden;
    }

    .img-frame:before {
        padding: 35%;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        margin: auto;
    }

    .truckmove {
        width: 125%;
    }

    .btn--primary {
        font-size: 16px;
        height: 40px;
        line-height: normal;
    }

    .common-section-all {
        padding: 40px 0;
    }

    .heading-style {
        margin-bottom: 25px;
    }

    .heading-style h4 {
        font-size: 20px;
        margin: 12px 0;
    }

    .heading-style h5 {
        font-size: 24px;
    }

    .heading-style h4 {
        font-size: 20px;
    }

    .heading-style p {
        font-size: 14px;
        line-height: 23px;
    }

    .finger-tips ul {
        margin-top: 20px;
    }
}

@media (max-width: 575px) {
    .btn--primary--outline, .btn--primary, .btn--secondary {
        font-size: 14px;
    }
    .heading-style h5 {
        font-size: 21px;
    }

    .home-banner-texter p {
        font-size: 14px;
    }

    .btn--primary {
        font-size: 14px;
        height: 40px;
    }

    .common-section-all {
        padding: 30px 0;
    }

    .heading-style {
        margin-bottom: 20px;
    }

    .heading-style h4 {
        font-size: 18px;
        margin: 10px 0;
        line-height: 22px;
    }

    .inner-banner .home-banner-texter {
        padding: 50px 0 30px;
    }

    .heading-style p,
    .business-customer ul li {
        font-size: 14px;
        line-height: 22px;
    }

}

@media (max-width: 375px) {

    .heading-style h5 {
        font-size: 20px;
    }

    .heading-style h4 {
        font-size: 16px;
    }
}