.section-banner .banner-images {
    /* position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    top: 0; */
}

.section-banner .banner-images img.for-one {
    width: 50vw;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    top: 0;
}

.section-banner.art-fleet.build-pump .home-banner-texter h1 span.text-white {
    font-size: 45px;
}
.section-banner.art-fleet.build-pump .home-banner-texter h1 span {
    font-size: 70px;
    line-height: 65px;
    
}


.build-pump {
    position: relative;
}

.build-pump::before {
    position: absolute;
    content: "";
    left: 0;
    width: 50vw;
    height: 100%;
    background: url(../../assets/images/build-pumpedbg.svg) no-repeat left;
    background-size: contain;
    z-index: -1;
}
[data-theme = 'light' ] .build-pump::before {
    background: url(../../assets/images/build-pumpedbgwhite.svg) no-repeat left;
    background-size: contain;
}
.build-serve {
    background-color: rgba(85, 29, 135, 1);
    background: linear-gradient(319.35deg, rgba(85, 29, 135, 0.1) 5.57%, rgba(85, 29, 135, 0.73) 51.88%, rgba(85, 29, 135, 0.16) 94.25%);
}
[data-theme = 'light'] .build-serve{
    background: linear-gradient(319.35deg, rgba(85, 29, 135, 0.1) 5.57%, rgb(241 243 243) 51.88%, rgba(85, 29, 135, 0.16) 94.25%);
}

.tabing-list .nav-tabs {
    border-bottom: 0;
    align-items: center;
    justify-content: center;
}

.tabing-list .nav-tabs li {
    margin-bottom: 0;
}

.tabing-list .nav-tabs li button {
    padding: 10px 50px;
    min-width: 300px;
    border-radius: 0;
    background: #22242D;
    border: 1px solid #5D627C;
    font-family: var(--AvenirHeavy);
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
}
[data-theme= 'light'] .tabing-list .nav-tabs li:last-child button{
    border-right: 0;
}
[data-theme= 'light'] .tabing-list .nav-tabs li button{
    background: #f1f3f3;
    color: #22242D;    
    border: 0;
    border-right: 1px solid #5D627C;    
}
.tabing-list .nav-tabs li button.active {
    background: #ff7900;
    color: #fff;
}

.highQuality-fuel .heading-style {
    margin-bottom: 50px !important;
}

.highQuality-fuel .certified-icon i {
    background-image: linear-gradient(174.73deg, #22242D 56.72%, rgba(34, 36, 45, 0) 100.08%);
    background-color: transparent;
    border-radius: 0px;
    display: flex;
    position: relative;
    z-index: 1;
}
.highQuality-fuel .certified-icon i + span{
    margin-right: 25px;
    color: var(--white);
    height: 40px;
}
.highQuality-fuel .certified-icon i::before {
    background-image: linear-gradient(174.73deg, #ff7900 56.72%, rgba(34, 36, 45, 0) 100.08%);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: all ease-in-out 0.9s;
    height: 0;
}

.highQuality-fuel .certified-icon i:hover:before {
    bottom: 0;
    height: 100%;
}

.highQuality-fuel {
    position: relative;
}
.buidservetext{
    padding-left: 5%;
    padding-right: 15%;
}
[data-theme= 'light'] .build-serve .heading-style p, [data-theme= 'light'] .build-serve .heading-style h5{
    color: #000;
}
[data-theme='light'] .build-serve .bg-white {
    background-color: #d2d0d9 !important;
}

@media (min-width: 1366.99px) {
    .build-serve .heading-style p {
        font-size: 20px;
    }
}

@media (max-width: 1366.99px) {
    .section-banner.art-fleet.build-pump .home-banner-texter h1 span {
        font-size: 50px;
    }
}

@media (max-width:1200px) {
    .build-pump::before{
        background-size: cover;
    }
}
@media (max-width: 991px) {
    .tabing-list .nav-tabs li button {
        padding: 10px 25px;
        min-width: 180px;
        font-size: 16px;
    }

    .section-banner.art-fleet.build-pump .home-banner-texter h1 span.text-white {
        line-height: 35px;
    }
    .section-banner.art-fleet.build-pump .home-banner-texter h1 span {
        font-size: 40px;
        line-height: 40px;
    }

    .buildserve {
        padding: 0px 15px !important;
        border-radius: 5px;
        overflow: hidden;
    }
    .buildserve .build-serve figure img{
        object-fit: contain !important;
        height: auto;
    }
}

@media (max-width: 767px) {
    .tabing-list .nav-tabs li button {
        padding: 10px 20px;
        min-width: auto;
        font-size: 14px;
    }
    .section-banner.art-fleet.build-pump .home-banner-texter h1 span.text-white {
        font-size: 26px;
        display: block;
    }
  
}

@media (max-width: 575px) {
    .tabing-list .nav-tabs li button {
        padding: 5px 20px;
        font-size: 13px;
    }
}

@media (max-width: 375px) {
    .tabing-list .nav-tabs li button {
        font-size: 11px;
    }
}