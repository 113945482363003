.playstoreIcons{
margin-top: 55px;
margin-bottom: 55px;
}
.playstore-icons a {
  border-radius: 5px;
  display: inline-block;
  overflow: hidden;
  width: auto;
  height: auto;
  transition: all ease-in-out 0.8s;
  white-space: nowrap;
  position: relative;
  z-index: 99;

}
.playstore-icons a:before {
  position: absolute;
  content: "";
  height: 120%;
  background: linear-gradient(319.35deg, rgba(85, 29, 135, 0.1) 5.57%, rgb(112 112 112 / 41%) 51.88%, rgba(85, 29, 135, 0.16) 94.25%);
  z-index: 1;
  left: -20px;
  top: 10px;
  margin: -5px 0 0 -15px;
  transform-origin: 0 0;
  transition: all ease-in-out 0.5s;
  width: 25px;
  transform: rotate(-15deg);
  
}
@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
.playstore-icons a img {
  height: auto;
  width: 150px;
  
}
.playstore-icons a:hover::before {
 animation: mymove 0.5s;
}
.playstore-icons a:hover {
  width: auto;
  border-radius: 5px;
  transition: all ease-in-out .8s;
}
.playstore-icons p {
  font-size: 14px;
  font-weight: normal;
  line-height: 15px !important;
  color: var(--white);
  margin-left: 15px;
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
  transition: all ease-in-out .8s;
}
.playstore-icons a:hover p {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
}
@media (max-width: 1366.99px) {
  .playstoreIcons{
    margin-top: 38px;
    margin-bottom: 38px;
  }
  
}
@media (max-width: 991px) {
  .playstoreIcons{
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .playstore-icons a img {
    height: 40px;
  }
}
@media (max-width: 500px) {
  .playstore-icons a img{
    width: auto;
  }
}