.fleet-section{
    position: relative;
    z-index: 1;
}
.fleet-section::before{
    position: absolute;
    content: "";
    background: url(../assets/images/fleetbg.jpg) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.fleet-section::after{
    position: absolute;
    content: "";
    background-color: rgba(85, 29, 135, 0.92);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.fleet-section .heading-style{
    position: relative;
    z-index: 1;
}
.fleet-section .heading-style h5{
    text-transform: capitalize;
}
[data-theme='light'] .fleet-section .heading-style h5{
    color: #ffffff;
}
.fleet-section .heading-style p{
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 991px) {
    .fleet-section .heading-style p{
        font-size: 18px;
    }
}