.errorpage {
   padding-top: 200px;
   padding-bottom: 100px; 
}
[data-theme = 'light'] .errorpage figure img{
    filter: invert(1);
}
.errorpage .bg-white span{
    color: #000 !important;
}
.errorpage .home-banner-texter h1{
    text-transform: unset;
}
[data-theme = 'light'] .errorpage .btn--primary.bg-white{
    background-color: #fff !important;
    border: 1px solid var(--lightestgrey) !important;
}
[data-theme = 'light'] .errorpage .btn--primary{
    background-color: var(--bgpurple) !important;
    border: 1px solid var(--bgpurple);
}
.errorpage p{
    color: var(--white);
}