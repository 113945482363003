.control {
    position: absolute !important;
    top: 20px;
    left: 20px;
    width: 50px;
    min-width: 50px !important;
}
.video_block .control img{
    width: 25px;
    min-height: auto;
    min-width: auto;
    object-fit: contain ;
    border-radius: 0;
    position: relative;
}
section.common-section-all.videoblk .heading-style{
    padding-left: 15px;
    padding-right: 15px;
}
#Play_Pause{
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    min-width: 70px !important;   
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.5s;
}
.video_block:hover #Play_Pause{
    opacity: 1;
    visibility: visible;
}
.video_block::before{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
}

#Play_Pause img{
    width: 25px;
    min-height: auto;
    min-width: auto;
    object-fit: contain ;
    border-radius: 0;
}