.sidebar .widget {
    margin-bottom: 36px;
}

.widget-search form input {
    height: 50px;
    border: none;
    background: #22242D;
    border-radius: 6px;
    padding: 0 45px 0 20px;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);
}
[data-theme = 'light'] .widget-search form i img{
    filter: invert(1);
}
[data-theme = 'light'] .widget-search form input{
    background: #fff;
    border: 1px solid #22242D;
}
.sidebar .widget .widget-title h3 {
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: var(--white);
    font-weight: 900;
    padding-bottom: 15px;
    margin-bottom: 22px;
    position: relative;
}

.widget-categories>ul.cat-list {
    list-style: none;
    padding-left: 0;
}

.widget-categories>ul.cat-list>li.active a {
    color: rgba(255, 121, 0, 1);
}
.widget-categories>ul.cat-list>li:last-child {
    border-bottom: 0;
}
.widget-categories>ul.cat-list>li {
    margin-bottom: 15px;
    font-weight: normal;
     border-bottom: 1px solid #e5e5e5;
     padding-bottom: 15px;
}

.widget-categories>ul.cat-list>li>a {
    position: relative;
    text-decoration: none;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.6);
    /* border-bottom: 1px solid #e5e5e5; */
    width: 100%;
}
.admin-details img{ 
    padding: 2px;
    width: 30px;
    border-radius: 50px;
    display: inline-block;
    height: 30px;
    background-color: #22242d;
}
[data-theme = 'light'] .admin-details img{ 
    background: #7a7c81;

}
[data-theme = 'light'] .widget-categories>ul.cat-list>li>a{ 
    color: #000;
}
.widget-blog h4 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    margin-bottom: 10px;
}

.widget-blog a:hover span {
    color: #fff;
}
.MuiPaginationItem-root {
    color: rgb(255 255 255 / 87%) !important;
}
.blog-details .blogdetail figure img{
    height: auto;
}