.pricing-reel .box {
  position: relative;
  margin-bottom: 30px;
}

.price-box h4 {
  /* font-weight: 700; */
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 32px;
  text-transform: capitalize;
  font-family: var(--FuturaBold);
}

.price-box h4 span {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--AvenirNormal);
  display: block;
  margin-top: 21px;
}

.price-box h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase;
}

.price-box h2 span {
  font-weight: 900;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  color: #FFFFFF;
  text-transform: lowercase;
}

.price-box ul {
  margin-top: 32px;
  flex: 1 1 auto;
}

.price-box ul li {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  padding-left: 32px;
  position: relative;
  transition: all ease-in-out 0.5s;
}
.price-box ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  background: url(../assets/images/point-icon.svg) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
  transition: all ease-in-out 0.5s;
}
.pricebox:hover ul li:before, .pricebox.active ul li:before , .pricebox2 ul li:before{
  background: url(../assets/images/checkwhite.png) no-repeat center;
  background-size: contain;
  width: 20px;
  height: 20px;
  transition: all ease-in-out 0.5s;
}
.price-box ul li i {
  margin-right: 15px;
}

.pricebox {
  background: #22242D;
  border-radius: 10px;
  padding: 40px 33px;
  transition: all ease-in-out 0.5s;
  height: 100%;
}

.price-box ul li:last-child {
  margin-bottom: 0;
}
.price-box:hover h4, .price-box:hover li, .price-box:hover h2{
  color: #fff;
}
.price-box .pricebox:hover {
  background-color: #ff7900;
  transform: scale(1.02);
}

.pricebox0 {
  background: linear-gradient(180deg, #67686F 0%, #22242D 100%);
}
.pricebox1 {
  background: linear-gradient(180deg, #C0C0C0 0%, #797979 100%);
}
.pricebox2 {
  background: linear-gradient(180deg, #FFBB30 0%, #FF9125 100%);
}
.pricebox3 {
  background: linear-gradient(180deg, #525252 0%, #2E2E2E 100%);
}

@media (max-width: 1366px) {
  .pricebox{
    padding: 40px 20px;
  }
}
@media (max-width: 991px) {
  .price-box h2{
    font-size: 35px;
  }
  .price-box h4{
    margin-bottom: 25px;
  }
  .price-box h4 span{
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .price-box h2{
    font-size: 28px;
  }
  .price-box h4{
    margin-bottom: 15px;
  }
  .price-box h4 span{
    margin-top: 10px;
  }
}