.contact-form .form-control,
.contact-form textarea,
.contact-form select {
    border: 1px solid rgba(137, 139, 145, 1);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    background-color: transparent;
    min-height: 48px;
    max-height: 228px;
    color: var(--white);
    padding: 0.375rem 0.75rem;
}
.contact-form select{
    appearance: none;
    color: rgba(137, 139, 145, 1) !important;
}
.contact-form label {
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: var(--white);
    margin-bottom: 15px;
    /* text-transform: capitalize; */
}

.contact-form {
    border: 2px solid #22242D;
}
[data-theme='light'] .contact-form{
    border: 2px solid var(--lightestgreyborder);
    background-color: rgb(241 243 243);
}
span.input-feedback {
    position: absolute;
    font-size: 12px;
}
.zipcode-listing {
    position: absolute;
    width: calc(100% - 30px);
    max-height: 200px;
    overflow: auto;
    background-color: #fff;
    border-radius: 5px;
    margin: auto;
    left: 15px;
    z-index: 9;
}

.zipcode-listing li {
    border-bottom: 1px solid rgb(204 204 204 / 35%);
    padding: 5px;
    color: var(--lightgrey);
    margin-bottom: 0;
}

.zipcode-listing li:hover {
    background-color: var(--primary);
    color: #fff;
}
@media (max-width:1200px) {
    .contact-form figure img{
        object-fit: cover;
        object-position: center;
        height: 250px;

    }
}