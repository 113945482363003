.laptopslide.img-frame .image-slide{
    top: 46px;
    width: calc(100% - 23%);
    height: calc(100% - 0px);
    z-index: 0;
}

.laptopslide.img-frame:before {
    display: none;
}

.img-frame img {
    width: 100%;
}

.macbook.img-frame .image-slide {
    width: calc(100% - 9%);
    height: calc(100% - 90px);
    top: 43px;
}

@media (max-width: 1366px) {
    .laptopslide.img-frame .image-slide {
        top: 30px;
    }
}
@media (max-width: 767px) {
    .laptopslide.img-frame .image-slide {
        top: 25px;
    }
}

@media (max-width: 575px) {
    .laptopslide.img-frame .image-slide {
        top: 15px;
    }
}