.retailsurface .home-banner-banner {
	z-index: 1;
	position: relative;
}

.retailsurface .container {
	padding: 0 !important;
}

.retailsurface {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	text-align: center;
	/* margin-top: 0px; */
	padding-top: 0px;
	background-repeat: no-repeat;
	position: relative;
	margin-bottom: 0;
	overflow: inherit;
	height: auto;
}

.retailsurface::before {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	top: 0;
	height: 720px;
	background: #22242d;

}

[data-theme='light'] .retailsurface .home-banner-texter h5 {
	color: #22242d;
}

[data-theme='light'] .retailsurface {
	background-color: transparent;
}

[data-theme='light'] .retailsurface::before {
	background-color: #f1f3f3;
}

.retailsurface .container {
	position: relative;
	height: 100%;
}

.retailsurface .container .lines {
	min-height: 720px;
	overflow: hidden;
	left: 0;
	right: 0;

}

.retailsurface .container .lines::before {
	background: var(--Lightestwhite);
	position: absolute;
	content: "";
	height: 720px;
	width: 1px;
	top: 0;
	right: 28.3%;
}

.retailsurface .container .lines .circle::after {
	position: absolute;
	content: "";
	width: 20px;
	height: 60px;
	left: 20px;
	top: 20px;
	background: url(../../assets/images/truckmove.svg) no-repeat center;
	background-size: contain;
	z-index: 1;
	-webkit-animation: movers 40s infinite forwards;
	animation: movers 40s infinite forwards;
}

@keyframes movers {
	0% {
		top: 0;
	}

	5% {
		top: 0;
		transition: all 0.6s;
		transform: rotate(180deg);
	}

	45% {
		top: 690px;
		transition: all 0.6s;
		transform: rotate(180deg);
	}

	50% {
		top: 690px;
		transition: all 0.6s;
		transform: rotate(0deg);
	}

	100% {
		top: 0;
	}
}

@keyframes movs {
	0% {
		top: 690px;
	}

	5% {
		top: 690px;
		transition: all 0.6s;
		transform: rotate(0deg);
	}

	45% {
		top: 0px;
		transition: all 0.6s;
		transform: rotate(0deg);
	}

	50% {
		top: 0px;
		transition: all 0.6s;
		transform: rotate(180deg);
	}

	100% {
		top: 690px;
		transition: all 0.6s;
		transform: rotate(180deg);
	}
}

@keyframes mover {
	0% {
		top: 400px;
		transition: all 0.6s;
	}

	30% {
		top: 0px;
		transition: all 0.6s;
		transform: rotate(0deg);
	}

	35% {
		top: 0px;
		transition: all 0.6s;
		transform: rotate(180deg);
	}

	100% {
		top: 690px;
		transition: all 0.6s;
		transform: rotate(180deg);
	}
}

.retailsurface .container .lines .circle::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 60px;
	right: 27.6%;
	top: 10px;
	background: url(../../assets/images/truckmove.svg) no-repeat center;
	background-size: contain;
	-webkit-animation: mover 40s infinite forwards;
	animation: mover 40s infinite forwards;
}

.retailsurface .container .lines .circleorange::after {
	background: var(--Lightestwhite);
	position: absolute;
	content: "";
	height: 720px;
	width: 1px;
	top: 0;
	right: 40px;
}

.retailsurface .container .lines .circleorange::before {
	position: absolute;
	content: "";
	width: 20px;
	height: 60px;
	right: 30px;
	top: 10px;
	background: url(../../assets/images/truckmove.svg) no-repeat center;
	background-size: contain;
	z-index: 1;
	-webkit-animation: movs 40s infinite forwards;
	animation: movs 40s infinite forwards;
}

.retailsurface .container .lines::after {
	background: var(--Lightestwhite);
	position: absolute;
	content: "";
	height: 720px;
	width: 1px;
	top: 0;
	left: 30px;
}

.retailsurface .retailsurfaceblock {
	position: relative;
	z-index: 1;
	padding-bottom: 0 !important;
}

.retailsurface .playstoreIcons {
	margin-top: 30px;
}

.retailsurface .heading-style {
	margin-bottom: 28px;
}

.retailsurface .home-banner-texter {
	padding-top: calc(75px - 3rem);
}

.retailsurface .home-banner-texter h5 {
	font-style: normal;
	font-weight: 900;
	font-size: 60px;
	line-height: 65px;
	text-align: center;
	text-transform: uppercase;
	font-family: var(--FuturaBold);
	color: #ffffff;
	top: 0;
}

.retailsurface .home-banner-texter h4 {
	font-size: 20px;
}

.retailsurface .storIcons .row .playstore-icons {
	justify-content: center !important;
}

.retailsurface .storIcons .row {
	padding: 0 !important;
}

/* @keyframes roll-out {
	0% {
		-webkit-transform: translatey(0);
		transform: translatey(0);
	}

	100% {
		-webkit-transform: translatey(700px);
		transform: translatey(700px) rotateY(180deg);
	}
} */

/* @keyframes roll-out-1 {
	0% {
		-webkit-transform: translatey(700px);
		transform: translatey(700px);
	}

	100% {
		-webkit-transform: translatey(100px);
		transform: translatey(100px);
	}
}

@keyframes roll-in-top {
	0% {
		-webkit-transform: translateY(700px);
		transform: translateY(700px);
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
} */


.banner-imagess img.for-one {
	width: 50vw;
	height: 100%;
	object-fit: cover;
}

.banner-imagess i {
	position: relative;
	overflow: hidden;
	height: 100%;
}

.banner-imagess i::before {
	position: absolute;
	content: "";
	/* background-color: rgba(85, 29, 135, 0.7); */
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 0 20px 20px 0;
}

.banner-imagess img.for-two {
	visibility: visible;
	animation-name: fadeInRight;
	display: inline;
	position: absolute;
	width: auto;
	top: 0;
	bottom: 0;
	margin: auto;
	height: 70vh;
	right: -9vw;
}

.banner-imagess {
	position: absolute;
	left: -6vw;
	bottom: 0;
	height: calc(100vh - 90px);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	top: 0;

}

.needgas .row {
	height: calc(100vh - 90px);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.needgas .heading-style h5 {
	text-transform: capitalize;
}

.retailbanner {
	margin-top: -500px;
}

.clienttestimonial .thumbnail-image figure {
	padding: 0;
}

.clienttestimonial .thumbnail-image figure img {
	height: 100%;
	width: 100%;
}

.clienttestimonial .thumbnail-image {
	border: 0;
	background-color: transparent;
}

.smartgetpumped img {
	padding: 0 15px;
}

@media (max-width: 1366.98px) {
	.retailsurface .heading-style {
		margin-bottom: 10px;
	}

	.retailsurface .home-banner-texter h5 {
		font-size: 40px;
		line-height: 45px;
	}

	.retailsurface .home-banner-texter h4 {
		margin: 10px 0;
	}

	.retailsurface {
		height: auto;
	}

	.retailsurface .container .lines,
	.retailsurface::before,
	.retailsurface .container .lines::before,
	.retailsurface .container .lines .circleorange::after,
	.retailsurface .container .lines .circleorange::after,
	.retailsurface .container .lines::after {
		height: 82vh;
		min-height: 520px;
	}

	@keyframes movers {
		0% {
			top: 0;
		}

		5% {
			top: 0;
			transition: all 0.6s;
			transform: rotate(180deg);
		}

		45% {
			top: 400px;
			transition: all 0.6s;
			transform: rotate(180deg);
		}

		50% {
			top: 400px;
			transition: all 0.6s;
			transform: rotate(0deg);
		}

		100% {
			top: 0;
		}
	}

	@keyframes movs {
		0% {
			top: 450px;
		}

		5% {
			top: 450px;
			transition: all 0.6s;
			transform: rotate(0deg);
		}

		45% {
			top: 0px;
			transition: all 0.6s;
			transform: rotate(0deg);
		}

		50% {
			top: 0px;
			transition: all 0.6s;
			transform: rotate(180deg);
		}

		100% {
			top: 450px;
			transition: all 0.6s;
			transform: rotate(180deg);
		}
	}

	@keyframes mover {
		0% {
			top: 400px;
			transition: all 0.6s;
		}

		30% {
			top: 0px;
			transition: all 0.6s;
			transform: rotate(0deg);
		}

		35% {
			top: 0px;
			transition: all 0.6s;
			transform: rotate(180deg);
		}

		100% {
			top: 450px;
			transition: all 0.6s;
			transform: rotate(180deg);
		}
	}
}

@media (max-width: 991px) and (min-width: 480px) {}

@media (max-width: 991px) {
	.retailsurface .home-banner-texter {
		padding-bottom: 0 !important;
	}

	.retailsurface .container .lines,
	.retailsurface::before,
	.retailsurface .container .lines::before {
		max-height: 570px;
	}

	.retailsurface .container .lines .circle::before {
		right: 26.5%;
	}

	.retailsurface {
		height: auto;
		min-height: 460px;
	}

	.retailsurface .home-banner-banner {
		margin-top: 0px;
	}

	.retailsurface .home-banner-banner .col-4:nth-child(2) img {
		transform: scale(1.2);
	}

	.banner-imagess {
		margin-bottom: 50px;
		position: relative;
	}

	.banner-imagess img.for-two {
		right: auto;
		left: 40%;
	}

	.needgas .row {
		height: auto;
	}

	.retailsurface .home-banner-texter h5 {
		font-size: 29px;
		line-height: 34px;
	}

	.retailsurface .home-banner-texter h4 {
		font-size: 16px;
	}

}

@media (max-width: 767px) {

	.retailsurface .container .lines,
	.retailsurface::before,
	.retailsurface .container .lines::before,
	.retailsurface .container .lines .circleorange::after,
	.retailsurface .container .lines .circleorange::after {
		max-height: 450px;
	}

	.banner-imagess img.for-two {
		left: 20%;
		height: 50vh;
	}

	.banner-imagess {
		height: calc(80vh - 90px);
	}
}

@media (max-width: 575px) {

	.retailsurface .container .lines,
	.retailsurface::before,
	.retailsurface .container .lines::before,
	.retailsurface .container .lines .circleorange::after,
	.retailsurface .container .lines .circleorange::after {
		min-height: 380px;
		max-height: 380px;
	}

	.retailsurface {
		min-height: 380px;
	}
}