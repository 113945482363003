.certified-banner img {
        /* -moz-filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
        -webkit-filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
        filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1)); */
}


.certified-icon i {
        width: 150px;
        height: 150px;
        background-color: rgba(34, 36, 45, 1);
        margin-right: 32px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
}

[data-theme='light'] .certified-icon i:hover img {
        filter: none;
}

[data-theme='light'] footer .certified-icon i {
        background-color: rgb(34 36 45 / 16%);
}

[data-theme='light'] .certified-icon i {
        background-color: #f1f3f3;
}

[data-theme='light'] .certified-icon i img {
        filter: none;
}

[data-theme='light'] .highQuality-fuel .certified-icon i:hover img {
        filter: none;
}
[data-theme='light'] .highQuality-fuel .certified-icon i {
        background-image: linear-gradient(174.73deg, #f1f3f3 56.72%, rgba(34, 36, 45, 0) 100.08%);
}

[data-theme='light'] .highQuality-fuel .certified-icon i img {
        filter: invert(1);
}

.certified-icon i img {
        transition: all ease-in-out 2s;
}

.certified-icon i:hover img {
        transform: scale(1.2);
}

.dots-orange {
        position: relative;
        z-index: 1;
}

.certified-icon li:last-child i+span {
        margin-right: 0;
}

.certified-icon li:last-child i {
        margin-right: 0;
}

@media (min-width: 1366px) {
        .certified-icon i {
                width: 170px;
        }
}
@media (max-width: 1024.98px) {
        body .certified-icon i {
                width: 130px;
                height: 120px;
        }

        .certified-icon i {
                margin-right: 15px;
        }

}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
        body .certified-icon i {
                width: 130px;
                height: 120px;
        }

        .highQuality-fuel .certified-icon i span {
                font-size: 12px;
        }

        .certified-icon i img {
                /* width: 40px; */
                object-fit: contain;
        }
}

@media (max-width: 480.98px) {
        .highQuality-fuel .certified-icon i img {
                width: 35px;
        }

        .highQuality-fuel .certified-icon i {
                width: 90px;
                height: 100px;
        }
}