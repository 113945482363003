.scenarios-icon {
    width: 130px;
    height: 130px;
    background: rgba(34, 36, 45, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 16px;
    margin: 30px auto;
    transition: all ease-in-out 0.5s;
}
[data-theme = 'light'] .scenarios-icon {
    background: rgb(34, 36, 45, 0.5);
}
/* .scenarios-reel .box .scenarios-icon img{
    transition: all ease-in-out 0.5s;
} */
.test-title h4 {
    font-family: var(--AvenirHeavy);
    font-style: normal;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: var(--white);
    text-align: center;
}

.scenarios-reel .box {
    text-align: center;
    border: 1px solid #22242D;
    padding-bottom: 30px;
}
[data-theme = 'light'] .scenarios-reel .box{
    border: 1px solid #bec3c3;
}
.scenarios-reel .box:hover .scenarios-icon {
    background-color: rgb(255 121 0);
}

.scenarios-reel .slick-initialized .slick-slide {
    padding: 0 30px;
}

.slick-arrow.slick-disabled {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

[data-theme='light'] .slick-arrow.slick-disabled {
    background-color: rgb(122 124 129) !important;
}

@media (max-width: 1200.98px) {
    .scenarios-icon {
        width: 110px;
        height: 110px;
    }

    .scenarios-reel .slick-initialized .slick-slide {
        padding: 0 20px;
    }
}

@media (max-width: 575.98px) {
    .slick-slide.slick-active.slick-center.slick-current .scenarios-icon.text-center {
        background: var(--primary);
    }
}