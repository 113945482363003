.businessfleet.gasoline.inner-banner .home-banner-texter h1 span{
font-size: 90px;
line-height: 90px;
}
.listing-list .downloadschedulelising li {
    background: transparent;
    margin-bottom: 0;
    text-align: left;
}

.downloadschedule .listing-list {
    /* padding-top: 50px; */
    margin-bottom: 35px;
}

.downloadschedule .playstoreIcons {
    /* margin-top: 0 !important; */
}

.downloadschedule {
    padding: 25px 0;
}

.downloadschedule .listing-list h5 {
    margin-bottom: 25px;
    font-size: 32px;
}
.gasolinetabs .macbook.img-frame .image-slide {
    /* width: calc(100% - 22%);
    height: calc(100% + 9px); */
}
@media (max-width: 991px) {
    .businessfleet.gasoline.inner-banner .home-banner-texter h1 span{
        font-size: 70px;
        line-height: 70px;
        }
        .fleet-section .heading-style p br{
display: none;
        }
        .gasolinecontact figure img{
object-position: bottom;
        }
}
@media (max-width: 767px) {
    .businessfleet.gasoline.inner-banner .home-banner-texter h1 span{
        font-size: 50px;
        line-height: 50px;
        }
}