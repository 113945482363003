.desel-def.inner-banner .home-banner-texter h1 span.text-white {
    font-size: 85px;
}

.desel-def.inner-banner .home-banner-texter h1 span.text-orange {
    font-size: 99px;
    line-height: 80px;
}

.desel-def::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background-image: url(../../assets/images/build-pumpedbg.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50vw;
    height: 100%;
    z-index: -1;
}
[data-theme = 'light' ] .desel-def::before {
    background: url(../../assets/images/build-pumpedbgwhite.svg) no-repeat left;        
    background-size: cover;
}
.desel-def .home-banner-texter p{
    color: var(--paragraphcolor);
}
.efficient-delivery .dots-orange::before {
    bottom: -12%;
    right: -19px;
}

.efficient-delivery.bg-grey::before {
    position: absolute;
    content: "";
    background-color: rgba(34, 36, 45, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}
[data-theme='light'] .efficient-delivery.bg-grey{
    background: #f1f3f3 !important;
}
[data-theme= 'light'] .efficient-delivery.bg-grey::before{
    background-color: rgba(210, 210, 210, 0.2);
}
.efficient-delivery.bg-grey {
    position: relative;
    z-index: 1;

}

@media (max-width: 1366.98px) {
    .desel-def.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 70px
    }

    .desel-def.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 80px;
        line-height: 70px;
    }
}

@media (max-width: 991px) {
    .efficient-delivery .dots-orange::before {
        right: 0;
    }

    .desel-def.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 60px
    }

    .desel-def.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 70px;
        line-height: 70px;
    }
}

@media (max-width: 767px) {
    .desel-def.inner-banner .home-banner-texter {
        padding-top: 0 !important;
    }

    .desel-def.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 40px
    }

    .desel-def.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 47px;
        line-height: 50px;
    }
}