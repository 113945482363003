.blogdetail p{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 16px;
}
.blogdetail h4{
    font-size: 30px;
    margin-bottom: 25px;
    line-height: normal;
    font-weight: 900;
}
.posted-details i{
    display: flex;
}