.fueltechsafety .background-image::before {
    z-index: -1 !important;
}

.fueltechsafety.inner-banner .home-banner-texter {
    z-index: 9;
    position: relative;
}

.fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey-content small {
    background-color: rgba(34, 36, 45, 1);
    padding: 10px 20px;
    font-size: 48px !important;
    display: inline-block;
    line-height: 45px;
}
[data-theme='light'] .fueltechsafety.inner-banner .background-image img{
    z-index: -1;
}
[data-theme='light'] .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey-content small {
    background-color: #cccccd;
}
[data-theme='light'] .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey-content small,
[data-theme='light'] .fueltechsafety.inner-banner .home-banner-texter h1 span.text-white {
    color: #000 !important;
}
[data-theme='light'] .fueltechsafety.inner-banner .home-banner-texter h1 span.text-white:first-child{
    color: #000 !important;
}
.fueltechsafety.inner-banner .home-banner-texter h1 span.text-orange {
    font-size: 95px;
    line-height: 102px;
}

.fueltechsafety.inner-banner .home-banner-texter h1 span.text-white {
    font-size: 90.2146px;
    line-height: 37px;
}

.btn--hover:hover::before,
.btn--hover:hover::after {
    display: none;
}

.btn--hover:hover {
    background-color: #fff;
}

.ourfueltechthumbbanner {
    position: absolute;
    right: -7%;
    top: 50%;
    width: 60%;
    transform: translateY(-50%);
    z-index: -1;
}

[data-theme='light'] .inner-banner.fueltechsafety {
    background-color: rgba(210, 210, 210, 0.2) !important;
}
[data-theme='light'] .fueltechsafety.inner-banner.inner-banner .background-image::before,  [data-theme='light'] .inner-banner.fueltechsafety:before{
 display: none;
}
.fueltechsafety:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.36;
    content: "";
    z-index: 1;
}

@media (min-width: 1366px) {
    .ourfueltechthumbbanner {
        width: 65%;
    }
}

@media (max-width: 1366px) {
    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 58px;
        line-height: 62px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 54.2146px;
        line-height: 37px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey small {
        font-size: 30px !important;
        padding: 0px 15px;
    }
    .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey-content small{
        padding: 0px 20px;
        font-size: 28px !important;
    }
}

@media (max-width: 991px) {
    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 58px;
        line-height: 62px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 54.2146px;
        line-height: 37px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey small {
        font-size: 30px !important;
        padding: 0px 15px;
    }

    .fueltechsafety.inner-banner .home-banner-texter {
        padding: 15px 15px 15px;
    }

}

@media (max-width: 1024px) {

}
@media (max-width: 767px) {
    .safety-dots {
        padding-bottom: 0;
    }

    .safety-dots .btn img {
        width: 80px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 45px;
        line-height: 50px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 42.2146px;
        line-height: 37px;
    }

    .fueltechsafety.inner-banner .home-banner-texter h1 span.bg-grey small {
        font-size: 22px !important;
        padding: 0px 15px;
    }
    .ourtechfuelcontact figure img{
object-position: left bottom;
    }
}

@media (max-width: 575px) {
    .ourfueltechthumbbanner {
        width: 80%;
    }
}