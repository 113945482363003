.macbook.img-frame {
    position: absolute;
    top: 20px;
    z-index: 9;
}

.macbook.img-frame .image-slide {
    top: -4px;
    width: calc(100% - 9%);
    height: calc(100% - 55px);
}

.macbook.img-frame:before {
    display: none;
}

.img-frame img {
    width: 100%;
}


@media (max-width: 575px) {
    .macbook.img-frame .videobg {
        top: 10px;
    }
}