.scroll-listing ul {
    direction: rtl;
    height: 490px;
    overflow-y: auto;
    overflow-x: hidden;
}
[data-theme= 'light'] .build-pump .home-banner-texter h1 , [data-theme= 'light'] .telematicdashboard.inner-banner .home-banner-texter h1 span.text-white, 
[data-theme= 'light'] .build-pump .home-banner-texter p{
    color: #000 !important;
}
.telematicdashboard .home-banner-texter p{
    color: var(--paragraphcolor);
}
.scroll-listing ul li {
    direction: ltr;
    margin-left: 15px;
    text-align: left;
}

.telematicdashboard.inner-banner .home-banner-texter h1 span.text-white {
    font-size: 90px;
}

.telematicdashboard.inner-banner .home-banner-texter h1 span.f-75 {
    font-size: 75px;
}
body .telematicdashboardcontact .form-group{
    margin-bottom: 14px;
}

@media (max-width: 1366.98px) {

    /* .telematicdashboard .home-banner-texter h1 span{
    padding-top: 50px;
    padding-bottom: 50px;
    }  */
    .telematicdashboard.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 60px;
    }

    .telematicdashboard.inner-banner .home-banner-texter h1 span.f-75 {
        font-size: 50px;
    }
}
@media (max-width: 991px) {
    /* .build-pump{
        min-height: 540px;
    } */
    [data-theme= 'light'] .build-pump .home-banner-texter h1 , [data-theme= 'light'] .build-pump.inner-banner .home-banner-texter h1 span.text-white, 
[data-theme= 'light'] .build-pump .home-banner-texter p{
    color: #fff !important;
}
    .section-banner.build-pump .container>.row{
        min-height: 500px;
    }
    .build-pump .home-banner-texter{
        padding: 50px 0 ;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    .build-pump .home-banner-texter h1{
        padding-bottom: 0;
    }
    .build-pump::before{
        width: 100vw;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .section-banner .banner-images img.for-one{
        width: 100vw;
        z-index: -2;
    }
    .telematicdashboard.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 60px;
    }

    .telematicdashboard.inner-banner .home-banner-texter h1 span.f-75 {
        font-size: 50px;
    }
    .scroll-listing .listing-list ul li{
        text-align: left;
        margin-left: 0;
    }
    .scroll-listing ul{
        height: auto;
    }
}

@media (max-width: 767px) {
    .build-pump .home-banner-texter{
        align-items: center;
    }
    .telematicdashboard.inner-banner .home-banner-texter h1 span.f-75 {
        font-size: 42px;
    }
    .telematicdashboard.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 50px;
        line-height: 30px;
    }
    .section-banner.build-pump .container>.row{
        min-height: 400px;
    }
}
@media (max-width: 480px) {
    .section-banner.build-pump .container>.row{
    min-height: 350px;
}
}