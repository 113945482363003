.autonomous-fleet{
    background: url(../../assets/images/bg-autonomous.png) no-repeat center;
    background-size: cover;
}
[data-theme = 'light']  .autonomous-fleet{
    background: url(../../assets/images/bg-autonomouslight.png) no-repeat center;
    background-size: cover;
    background-color: rgba(210, 210, 210, 0.2);

}
[data-theme = 'light'] .autonomous-fleet .home-banner-texter h1{
color : #000  !important;
}
.autonomous-fleet.inner-banner .home-banner-texter h1 span.text-white{
    font-size: 81px;
    line-height: 82px;
    text-transform: uppercase;
}
.autonomous-fleet.inner-banner .home-banner-texter h1 span.text-orange{
    font-size: 54px;
    line-height: 56px;
    text-transform: uppercase;
}
.autonomous-fleet.inner-banner .home-banner-texter h1{
    font-size: 61px;
    line-height: 66px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: var(--FuturaBold);
}
.autonomous-video .dots-orange::before{
    right: 0%;
    bottom: -7%;
}

@media (max-width: 1366px) {
    .autonomous-fleet.inner-banner .home-banner-texter h1 span.text-white{
        font-size: 60px;
        line-height: 60px;
    }
    .autonomous-fleet.inner-banner .home-banner-texter h1 span.text-orange{
        font-size: 40px;
        line-height: 40px;
    }
    .autonomous-fleet.inner-banner .home-banner-texter h1{
        font-size: 45px;
        line-height: 45px;
    }
    .intro-sistercompny p br{
        display: none;
    }
}

@media (max-width: 991px) {
    .contactusmargin{
        margin-top: 0;
    }
    .autonomous-video .dots-orange::before{
        bottom: -6%;
    }
    /* .video_block video{
        object-fit: cover;
        min-height: 310px;
        height: auto;
    } */
    .autonomous-fleet{
        background-color: #14151b;
    }
}
@media (max-width: 767px) {
    
    .autonomous-fleet.inner-banner .home-banner-texter{
padding: 0 !important;
    }
    .autonomous-fleet.inner-banner .home-banner-texter h1 span.text-white{
        font-size: 46px;
        line-height: 46px;
    }
    .autonomous-fleet.inner-banner .home-banner-texter h1 span.text-orange{
        font-size: 30px;
        line-height: 30px;
    }
    .autonomous-fleet.inner-banner .home-banner-texter h1{
        font-size: 35px;
        line-height: 35px;
    }
}