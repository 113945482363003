.geofencing::before {
    background: url(../../assets//images/offensing_bg.svg);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-attachment: fixed;
    background-size: contain;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -1;
    background-position-x: 0px;
    animation: road-moving 10s infinite linear;
}
[data-theme='light'] .geofencing.ourprocess::before{
z-index: -1;
opacity: 0.5;

}
[data-theme='light'] .geofencing::before{
    opacity: 0.5;
}
[data-theme='light'] .geofencing .home-banner-texter h1 span.text-white{
    color: #FFFFFF !important;
}
[data-theme='light'] .geofencing .home-banner-texter p{
    color: #FFFFFF;
}
[data-theme='light'] .inner-banner.geofencing{
    background-color: rgb(72 72 72);
}
[data-theme='light'] .ourprocess .home-banner-texter p{
color: #FFFFFF;
}
.geofencing .home-banner-texter p{
    color: var(--paragraphcolor);
}
.geofencing .home-banner-texter h1 span.text-orange {
    font-size: 65px;
}

.geofencing .home-banner-texter h1 span {
    font-family: var(--FuturaBold);
    font-size: 62px;
    line-height: 70px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.fr.geofencing .certified-banner.p-4{
    padding: 0 !important;
}
.fr.geofencing .mb-3{
    margin-bottom: 0 !important;
}
.fr.geofencing .row .dots-orange::before {
    display: none;
}

.fr.geofencing .row {
    flex-direction: inherit !important;
}

@keyframes road-moving {

    0%,
    100% {
        background-position-x: 0%;
    }

    10% {
        background-position-x: 25%;
    }

    20% {
        background-position-x: 25%;
    }

    30% {
        background-position-x: 65%;
    }


    40% {
        background-position-x: 65%;
    }

    50% {
        background-position-x: 100%;
    }
}

.img-shadow-none img {
    filter: none;
}

.img-shadow-none .dots-orange figure::before {
    display: none;
}

.img-shadow-none.geofencing::before {
    background-attachment: inherit
}
.geofencing.fr .mb-lg-3{
    margin-bottom:  0 !important;
}
@media (min-width: 991.98px) {
    .fr.geofencing .pb-md-4 {
        padding-bottom:  80px !important;
    }
}
@media (max-width: 1200px) {
    .geofencing.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 40px;
        line-height: 40px;
    }
    .geofencing.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 42px;
        line-height: 42px;
    }

    .geofencing.inner-banner .home-banner-texter p br {
        display: none;
    }
    .geofencing::before{
        background-attachment: inherit;
    }
    .geofencing.inner-banner .home-banner-texter{
padding: 0;
    }
}
@media (max-width: 767px) {
    .geofencing.inner-banner .home-banner-texter h1 span.text-white {
        font-size: 30px;
        line-height: 30px;
    }
    .geofencing.inner-banner .home-banner-texter h1 span.text-orange {
        font-size: 32px;
        line-height: 32px;
    }

}