.inner-banner.faq .home-banner-texter h1, .inner-banner.faq .home-banner-texter h1 span  {
font-size: 46px;
text-transform: capitalize;
margin-bottom: 30px;
}
.inner-banner.faq a{
    color: var(--white);
}
.section-banner.faq , .inner-banner.faq .background-image {
    height: 420px !important;
    display: flex;
    align-items: center;
}
.inner-banner.faq .background-image::before{
  background-color: rgba(0, 0, 0, 0.60);
  z-index: -1 !important;
}
.inner-banner .background-image img{
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  object-fit: cover;
  z-index: -100;
}
[data-theme='light'] .inner-banner .background-image img{
    z-index: 0;
}
.accordion-item .playstoreIcons{
    margin-bottom: 0;
    margin-top: 15px;
}
.accordion-item .getfleetqouteblock .getfleetqouteform{
    padding-top: 20px;
    padding-bottom: 0;
}
.accordion-item, .accordion-header .accordion-button{
   background: #22242D;
box-shadow: 0px 1px 2px rgba(85, 105, 135, 0.1);
border-radius: 6px; 
color: var(--white);
border: 0;
}

.accordion-item{
    margin-bottom: 20px;
    padding: 0;
}
.accordion-button::after{
    background: url(../assets//images/arrow-faq.svg) no-repeat center;
    background-size: contain;
    padding: 10px;
}
.accordion-button:not(.collapsed)::after{
    background: url(../assets//images/arrow-faq.svg) no-repeat center;
    background-size: contain;
    padding: 10px;
    transform: rotate(180deg);
}
.accordion-item .accordion-header button{
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: none;
    font-family: var(--AvenirHeavy);
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--white);
}
[data-theme= 'light' ] .accordion-item .accordion-body{
    color: #22242D;
}
[data-theme= 'light' ] .accordion-item,[data-theme= 'light' ] .accordion-header .accordion-button{
    background-color: #f1f3f3;
}
.accordion-item .accordion-body{
    font-family: var(--AvenirNormal);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #CFD0D1;
    padding-right: 80px;
    /* padding-bottom: 30px; */
    padding-top: 0px;
}
button.accordion-button.collapsed {
    /* padding-bottom: 30px; */
}
@media (max-width: 991px) {

    .inner-banner.faq .container{
        padding: 0 15px;
    }
    .inner-banner.faq .home-banner-texter{
        margin-top: 0;
        padding: 0;
    }
    .section-banner.faq, .inner-banner.faq .background-image{
        height: 330px !important;
    }
    .inner-banner.faq .home-banner-texter h1, .inner-banner.faq .home-banner-texter h1 span{
        font-size: 32px;
        margin-bottom: 22px;
    }
    
}