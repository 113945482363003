.IphoneSlider.img-frame .image-slide {
    top: -50px;
    width: calc(100% - 11%);
    height: calc(100% - 16px);
}

.IphoneSlider.img-frame:before {
    display: none;
}

.img-frame img {
    width: 100%;
}

/* .macbook.img-frame .image-slide {
    width: calc(100% - 9%);
    height: calc(100% - 90px);
    top: 0;
} */

@media (max-width: 1024px) {
    .IphoneSlider.img-frame .image-slide {
        top: -50px;
    }
}
@media (max-width: 991px) {
    .IphoneSlider.img-frame .image-slide {
        width: calc(100% - 11%);
        height: calc(100% - 16px);
        overflow: hidden;
        border-radius: 20px;
        top: -56px;
    }
    .download-schedule .fuel-screen .IphoneSlider.img-frame .image-slide img:first-child{
        left: 0;
        bottom: 0;
    }
}
@media (max-width: 767px) {
    .IphoneSlider.img-frame .image-slide {
        top: 20px;
        left: 0px;
    }
    .download-schedule .fuel-screen .IphoneSlider.img-frame .image-slide img:first-child{
        left: 0;
        bottom: 0;
    }
}

@media (max-width: 575px) {
    .IphoneSlider.img-frame .image-slide {
        top: 20px;
    }
}