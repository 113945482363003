.businessfleet.inner-banner .home-banner-texter h1 {
	font-size: 34px;
	font-weight: 900;
	font-family: var(--FuturaBold);	color: #fff;
}

.businessfleet.inner-banner .home-banner-texter h1 span {
	font-size: 76px;
	font-family: var(--FuturaBold);
	line-height: 80px;

}
[data-theme = 'light'] .businessfleet.gasoline.inner-banner .home-banner-texter h1 span.text-white{
	color: #fff !important;
}
.tracking-details {
	position: relative;
	border-radius: 25px;
	overflow: hidden;
	z-index: 1;
}

.tracking-details::before {
	position: absolute;
	content: "";
	left: 50%;
	right: 0;
	background: url(../../assets/images/trackingbg.svg) no-repeat center;
	background-size: cover;
	top: 0;
	bottom: 0;
	z-index: -1;
	/* -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
}
[data-theme= 'light'] .tracking-details .heading-style h5{
	color: #2d2d2d;
}
[data-theme= 'light'] .tracking-details::before{
	background: url(../../assets/images/trackingbgwhite.svg) no-repeat center;
	background-size: cover;
}
@-webkit-keyframes fade-in-right {
	0% {
		-webkit-transform: translateX(50px);
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes fade-in-right {
	0% {
		-webkit-transform: translateX(50px);
		transform: translateX(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
.tracking-details .heading-style h5 {
    color: #fff;
}
.background-video {
	height: 720px;
}

.background-video::before {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.70);
	content: "";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;
}
.businessfleet .video_block .control {
	display: none !important;
}
.businessfleet .video_block {
    position: absolute !important;
    z-index: -1;   
    border-radius: 0;
    top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
[data-theme='light'] .businessfleet .video_block{
	z-index: 0;
}
.inner-banner.inner-banner.businessfleet .background-image::before{
display: none;
}
.inner-banner.inner-banner .background-image::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
} 
.background-image::before {
    position: absolute;
    background-color: rgb(0, 0, 0, 0.6);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}
.background-video video,
.background-video iframe {
	background-size: cover;
	background-position: 50% 50%;
	position: absolute;
	margin: auto;
	width: 100%;
	height: 100%;
	right: 0%;
	bottom: 0%;
	top: 0%;
	left: 0%;
	object-fit: cover;
	z-index: -100;
}

.businessfleet .home-banner-texter {
	padding: 50px 0;
	position: relative;
	z-index: 99;
}

@media (max-width: 1366px) {
	.background-video {
		height: auto;
		max-height: 720px;
	}

	.businessfleet.inner-banner .home-banner-texter h1 span {
		font-size: 55px;
		line-height: 50px;
	}

	.businessfleet.inner-banner .home-banner-texter h1 {
		font-size: 24px;
		padding: 20px 0 0;
	}
}

@media (max-width: 991px) {
	.tracking-details::before{
		left: 0;
	}
	.tracking-details .heading-style{
		padding: 70px 30px 70px;
	}
	.businessfleet.inner-banner .background-image .video_block video{
		min-height: 100%;
		}
}
@media (max-width: 575px) {
	
	.businessfleet.inner-banner .home-banner-texter h1 span {
		font-size: 40px;
		line-height: 40px;
	}

	.businessfleet.inner-banner .home-banner-texter h1 {
		font-size: 18px;
		padding: 20px 0 0;
	}
	.tracking-details .heading-style{
		padding: 50px 15px;
	}
	.businessfleet.inner-banner .home-banner-texter{
padding-left: 15px;
padding-right: 15px;
	}
}