.inner-banner.changefueling,
.inner-banner .background-image::before {
    z-index: 1;
}

.changefueling.inner-banner .home-banner-texter h1 span {
    font-family: var(--FuturaBold);
    font-size: 75.7657px;
    line-height: 75px;
    text-transform: uppercase;

    color: var(--white);
}

.changefueling.inner-banner .home-banner-texter h1 span:nth-child(1) {
    font-size: 80px;
}

[data-theme='light'] .changefueling.inner-banner .home-banner-texter h1 span.text-white {
    color: #fff !important;
}

.foundation_logo {
    position: relative;
}

.foundation_logo .slick-arrow:hover {
    opacity: 1;
}

.foundation_logo .slick-arrow {
    opacity: 0.6;
}

.foundation_logo i {
    position: absolute;
    top: 102%;
    left: 0;
    right: 0;
    content: "";
    transform: translateY(-102%);
}

.changefueling .home-banner-texter {
    padding: 50px 0;
    position: relative;
    z-index: 9;
}
.changefueling.inner-banner.inner-banner .background-image::before{
z-index: 1 !important;
}
.foundation-dots .dots-purple::before {
    bottom: -17px;
}

.robotic_charge h2 {
    font-weight: 700;
    font-size: 35.4679px;
    line-height: 38px;
    font-family: var(--FuturaBold);

    text-transform: uppercase;

    color: #FFFFFF;
}

.robotic_charge {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
[data-theme = 'light'] .bg-grey.robotic_charge {
    background: #f1f3f3 !important;
}
[data-theme = 'light'] .bg-grey.robotic_charge .home-banner-texter h2 {
color: #000;
}
@media (min-width: 1200px) {
    .foundation_logo .slick-arrow.slick-prev {
        left: -15px;
    }

    .foundation_logo .slick-arrow.slick-next {
        right: -15px;
    }
}

@media (max-width: 991px) {
    .changefueling.inner-banner .home-banner-texter h1 span:nth-child(1) {
        font-size: 55px;
    }

    .changefueling.inner-banner .home-banner-texter h1 span {
        font-size: 55.7657px;
        line-height: 55px;
    }

    .highQuality-fuel .heading-style {
        margin-bottom: 30px !important;
    }
}

@media (max-width: 767px) {
    .changefueling.inner-banner .home-banner-texter h1 span:nth-child(1) {
        font-size: 38px;
    }

    .changefueling.inner-banner .home-banner-texter h1 span {
        font-size: 35.7657px;
        line-height: 35px;
    }
}