.contact-form .form-control,
.contact-form textarea {
    border: 1px solid rgba(137, 139, 145, 1);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    background-color: transparent;
    min-height: 48px;
    max-height: 228px;
    color: var(--white);
    padding: 0.375rem 0.75rem;
}

.contact-form .form-group {
    margin-bottom: 25px;
}

.contact-form label {
    font-weight: normal;
    font-size: 14px;
    line-height: 12px;
    color: var(--white);
    margin-bottom: 15px;
}

.contact-form {
    border: 2px solid #22242D;
}

span.input-feedback {
    position: absolute;
    font-size: 12px;
}
.getfleetqouteblock {
    overflow: hidden;
}

.getfleetqouteblock .getfleetqouteform{
    padding-top: 42px;
    padding-bottom: 42px;
}
[data-theme = 'light'] .contact-form .form-control, [data-theme = 'light'] .contact-form textarea{
    border: 1px solid rgba(137, 139, 145, 1);
}
@media (mIN-width:1200px) {
.getfleetqouteblock figure img{
        margin-bottom: -40px;
        margin-top: -40px;
    }
}


@media (max-width: 767px) {
    .contact-form label{
        line-height: 12px;
    }
    .contact-form .form-group{
        margin-bottom: 20px;
    }
    .contact-form .form-control, .contact-form textarea{
        min-height: 40px;
    }
    .getfleetqouteblock .getfleetqouteform{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}
@media (max-width: 480px) {
    .getfleetqouteblock .getfleetqouteform{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .contact-form .form-group{
        margin-bottom: 15px;
    }
}