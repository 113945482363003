.certified-banner figure {
	-moz-filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
	-webkit-filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
	filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
	display: block;
	border-radius: 20px;
	overflow: hidden;
}
[data-theme= 'light'] .certified-banner figure{
	-moz-filter: drop-shadow(21px 21px 0px #f1f3f3);
	-webkit-filter: drop-shadow(21px 21px 0px #f1f3f3);
	filter: drop-shadow(21px 21px 0px #f1f3f3);
}
.certified-banner figure img{
	transition: all  0.5s;
}
.certified-banner figure img:hover{
	-webkit-transform: scale(1.02) translateY(0);
	transform: scale(1.02) translateY(0);
			transition: all 0.5s;
}

  @keyframes kenburns-top {
	0% {
	  -webkit-transform: scale(1) translateY(0);
			  transform: scale(1) translateY(0);
	  -webkit-transform-origin: 5% 1%;
			  transform-origin: 5% 1%;
			  transition: all 0.5s;
	}
	50% {
	  -webkit-transform: scale(1.05) translateY(-15px);
			  transform: scale(1.05) translateY(-15px);
	  -webkit-transform-origin: top;
			  transform-origin: top;
			  transition: all 0.5s;
	}
	100% {
		-webkit-transform: scale(1) translateY(0);
				transform: scale(1) translateY(0);
		-webkit-transform-origin: 5% 1%;
				transform-origin: 5% 1%;
				transition: all 0.5s;
	  }
  }
  
.dots-orange {
	position: relative;
	z-index: 1;
}

.dots-orange::before {
	position: absolute;
	content: '';
	background: url(../assets/images/dots.svg) no-repeat center;
	background-size: contain;
	padding: 7% 10%;
	right: -2%;
	bottom: -7%;
	z-index: -2;
}

@media (max-width: 991px) {
	.dots-orange::before {
		right: -10px;
		bottom: -40px;
	}
}

@media (max-width: 767px) {
	.dots-orange::before {
		right: -5px;
		bottom: -28px;
	}
	
	.certified-banner figure {
		-moz-filter: drop-shadow(10px 10px 0px rgba(34, 36, 45, 1));
		-webkit-filter: drop-shadow(10px 10px 0px rgba(34, 36, 45, 1));
		filter: drop-shadow(10px 10px 0px rgba(34, 36, 45, 1));
	}
	[data-theme= 'light'] .certified-banner figure{
		-moz-filter: drop-shadow(10px 10px 0px #f1f3f3);
		-webkit-filter: drop-shadow(10px 10px 0px #f1f3f3);
		filter: drop-shadow(10px 10px 0px #f1f3f3);
	}
	
}
@media (max-width: 575px) {

}
@media (max-width: 575px) {
	.dots-orange::before {
		right: 5px;
		bottom: -11px;
	}

	
}