.widget-search i{
position: absolute;
right: 10px;
top: 10px;
opacity: 0.5;
}
.widget-search{position: relative;}
.sidebar ul.cat-list figure img {
    height: 140px;
    min-height: 140px;
    overflow: hidden;
    object-position: center;
}
@media (max-width: 767px) {
    .sidebar{
        margin-top: 50px;
    }
}