.mapscale::before {
    /* position: absolute;
    content: "";
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    background: url(../../assets/images/mapscale.jpg) no-repeat center;
    background-size: cover;
    z-index: -1; */
}

/* .mapscale::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
    background: rgba(34, 36, 45, 1);
    z-index: -1;
    opacity: 0.95;
    top: 0;
} */

.mapscale .home-banner-texter h5 {
    font-size: 62px;
    line-height: 55px;
    color: #FFFFFF;
    font-family: var(--FuturaMedium);
}

.mapscale .home-banner-texter h5 span.text-white {
    font-size: 89px;
    line-height: 70px;
    font-family: var(--FuturaHeavy);
    margin-top: 10px;
    display: inline-block;
}
[data-theme = 'light'] .mapscale .home-banner-texter h5 span.text-white{
    color: #FFFFFF !important;
}
.bggreydiff{
    background-color: rgba(34, 36, 45, 0.4);
}
[data-theme = 'light'] .bggreydiff{
    background-color: #f1f3f3;
}
.mapscale .home-banner-texter h5 span.text-orange {
    font-size: 80px;
    line-height: 80px;
    color: #FFFFFF !important;
    font-family: var(--FuturaBold);

}

.mapscale .home-banner-texter p {
    font-size: 18px;
    padding-left: 15px;
    margin-top: 20px;
}

.mapscale .arrow-curve::before {
    left: 50%;
}

@media (max-width: 1366px) {
    .mapscale .home-banner-texter h5 span.text-orange {
        font-size: 52px;
        line-height: 52px;
    }

    .mapscale .home-banner-texter h5 span.text-white {
        font-size: 59px;
        line-height: 59px;
    }

    .mapscale .home-banner-texter h5 {
        font-size: 40px;
        line-height: 25px;
    }

    .mapscale .home-banner-texter p {
        font-size: 16px;
        padding-left: 10px;
        margin-top: 10px;
    }

    .mapscale .home-banner-texter {
        margin-bottom: 42px;
        margin-top: 42px;
    }
}

@media (max-width: 991.99px) {
    .mapscale .home-banner-texter br {
        display: block;
    }
    .mapscale::after{
    width: 100%;
    opacity: 0.75;
    }
}
@media (max-width: 575.99px) {
    .mapscale .home-banner-texter h5 span.text-orange {
        font-size: 37px;
        line-height: 40px;
    }
    .mapscale .home-banner-texter h5 span.text-white {
        font-size: 41px;
        line-height: 39px;
        margin-top: 5px;
    }
    .mapscale .home-banner-texter h5 {
        font-size: 29px;
        line-height: 25px;
    }
}