.services .head-icon i {
    width: 75px;
    height: 75px;
    margin-top: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #22242D;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    margin-left: 40px;
    position: relative;
    z-index: 1;
}
[data-theme = 'light'] .services .head-icon i{
    background: #7a7b81;
}
.services .head-icon i img {
    height: 30px;
    transition: all ease-in-out 0.5s;
}

.services li:nth-child(1) .card .head-icon i::before {
    animation: 1s ease-out 1s 1 onetime;
}

.services li:nth-child(2) .card .head-icon i::before {
    animation: 1s ease-out 2s 1 onetime;
}

.services li:nth-child(3) .card .head-icon i::before {
    animation: 1s ease-out 3s 1 onetime;
}

.services li:nth-child(4) .card .head-icon i::before {
    animation: 1s ease-out 4s 1 onetime;
}

.services li:nth-child(5) .card .head-icon i::before {
    animation: 1s ease-out 5s 1 onetime;
}

.services li:nth-child(6) .card .head-icon i::before {
    animation: 1s ease-out 6s 1 onetime;
}

.services li:nth-child(1) .head-icon i img {
    animation: 1s ease-out 1s 1 onetimeimg;
}

.services li:nth-child(2) .head-icon i img {
    animation: 1s ease-out 2s 1 onetimeimg;
}

.services li:nth-child(3) .head-icon i img {
    animation: 1s ease-out 3s 1 onetimeimg;
}

.services li:nth-child(4) .head-icon i img {
    animation: 1s ease-out 4s 1 onetimeimg;
}

.services li:nth-child(5) .head-icon i img {
    animation: 1s ease-out 5s 1 onetimeimg;
}

.services li:nth-child(6) .head-icon i img {
    animation: 1s ease-out 6s 1 onetimeimg;
}

.services .card {
    background: #111217;
    border: 1px solid #22242D;
    border-radius: 4px;
    margin-bottom: 15px;
    transition: all ease-in-out 0.5s;
    position: relative;
    z-index: 1;
    height: 100%;
    min-height: 200px;
}
[data-theme='light'] .services .card{
    background-color: transparent;
    border-color: var(--lightestgreyborder);
}
.services .card::before {
    /* background-color: #22242D;
    position: absolute;
    content: "";
    left: 0;
    width: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    transition: all ease-in-out 0.5s; */
}

.services .card:hover::before {
    width: 100%;
}

.services .card .head-icon i::before {
    background-color: #FF7900;
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 1s;
    /* animation: 1s ease-out 2s 1 onetime; */
}

/* @keyframes onetime {
    0% {
        height: 0%;
        transition: all ease-in-out 2s;
    }

    50% {
        height: 100%;
        transition: all ease-in-out 2s;
    }

    100% {
        height: 0%;
        transition: all ease-in-out 2s;
    }
}

@keyframes onetimeimg {
    0% {
        transform: none;
    }

    50% {
        transform: scale(1.2) rotatey(360deg);
        transition: all ease-in-out 2s;
    }

    100% {
        transform: none;
    }
} */

.services .card:hover .head-icon i::before {
    height: 100%;
}

.services .card:hover i img {
    transform: scale(1.2) rotatey(360deg);
}

.services .card .card-body {
    padding-top: 25px;
    padding-bottom: 43px;
    padding-left: 20px;
    padding-right: 20px;


}
.services .card:hover .card-title, .services .card:hover .card-body p{
    opacity: 2;
}
.services .card .card-title{
    opacity: 1;
    transition: all ease-in-out 0.5s;
}
.services .card .card-body p{
    opacity: 1;
    transition: all ease-in-out 0.5s;
}
.services li.core-center .card .card-text {
        text-align: center;
}
.services li.core-center .card .card-title {
       text-align: center; 
}
.services .card .card-title {
    font-family: var(--AvenirHeavy);
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    color: var(--white);
    text-transform: capitalize;
    margin-bottom: 19px;

}

.services .card .card-text {
    font-family: var(--AvenirNormal);
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    /* color: #FFFFFF; */

}

.services .subhead_title .card {
    background-color: rgba(34, 36, 45, 1);
}

.services .subhead_title .card-title span.text-orange {

    font-size: 56px;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
}

.services .subhead_title h3.card-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: var(--AvenirHeavy);
    font-style: normal;
}

.services .subhead_title h3.card-title span.text-white {
    font-size: 79.5206px;
    text-align: center;
    text-transform: uppercase;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(255 255 255 / 32%);
    color: transparent !important;
    line-height: 90px;
}

.services .subhead_title h3.card-title span.text-white {
    display: inline-flex;
    position: relative;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
}

.services .subhead_title h3.card-title span.text-white::after {
    position: absolute;
    top: 0;
    bottom: -10px;
    left: 0;
    display: inline-block;
    overflow: hidden;
    width: 0%;
    white-space: pre;
    content: attr(data-text);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px hsla(0, 0%, 100%, 0.7);
    will-change: width;
    -webkit-animation: grow 7s cubic-bezier(0.86, 0, 0.07, 1) 0.4s infinite alternate both;
    animation: grow 7s cubic-bezier(0.86, 0, 0.07, 1) 0.4s infinite alternate both;
}

.services .subhead_title h3.card-title.text-white span {
    display: inline-block;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2);
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
}

@-webkit-keyframes grow {
    0%,
    10%,
    90%,
    100% {
        width: 0%;
    }

    50% {
        width: 100%;
    }
}

@keyframes grow {

    0%,
    10%,
    90%,
    100% {
        width: 0%;
    }

    50% {
        width: 100%;
    }
}
.services .headicon i {
    margin-left: 0;
    margin-top: 0;
    background: transparent;
    box-shadow: none;
}
[data-theme = 'light'] .services .headicon i{
    background-color: transparent;
}
[data-theme = 'light'] .services .headicon{
    background-color: #7a7b81;
}
.services .headicon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    background: #22242d;
    margin-left: 20px;
    height: 55px;
    margin: -30px auto 0;
    position: relative;
    z-index: 1;
}
.services .headicon h4{
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: var(--FuturaHeavy);
    letter-spacing: 0.08rem;
    margin-bottom: 0;
}
.services .headicon:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #ff7900;
    content: "";
    z-index: -1;
    transition: all ease-in-out 0.8s;
    width: 0;
}
.services li:hover .headicon:before {
    right: 0;
    width: 100%;
    transition: all ease-in-out 0.8s;
}
.services .headicon i::before{
    display: none;
}
@media (max-width: 767px) {
    .services .card {
        margin-bottom: 10px;
        min-height: auto;
    }
}

@media (max-width: 991px) {
    .services .subhead_title .card .card-body {
        padding-top: 0;
        padding-bottom: 30px;
    }
    .services .card .card-body {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .services .card .card-title {
        margin-bottom: 10px;
    }

    .services .head-icon i {
        width: 65px;
        height: 65px;
    }
}