.blog-details h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 27px;
    color: var(--white);
    margin-top: 17px;
    margin-bottom: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.blog-details h4 a{
    color: var(--white);
}

.blog-details p {
    font-size: 14px;
}

.meta-details span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.6);
}
[data-theme= 'light'] .meta-details span{
    color: rgba(0, 0, 0, 0.6);
}
.meta-details i {
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
}

.blogblock figure img {
    max-width: 100%;
    border-radius: 10px;
    height: 250px;
    min-height: 250px;
    object-fit: cover;

}

.fueling-details p {
    margin-top: 15px;
}

.fueling-details h5 span.text-orange {
    font-size: 98px;
    line-height: 85px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.fueling-details h5 span.text-white {
    font-weight: normal;
    font-size: 100px;
    line-height: 105px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

}

.fueling-details::before {
    background: linear-gradient(319.35deg, rgba(85, 29, 135, 0.1) 5.57%, rgba(85, 29, 135, 0.73) 51.88%, rgba(85, 29, 135, 0.16) 94.25%);
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 50%;
    opacity: 0.5;
}
[data-theme = 'light'] .fueling-details::before{
    background: linear-gradient(319.35deg, rgba(85, 29, 135, 0.1) 5.57%, #d9dddd 51.88%, rgba(85, 29, 135, 0.16) 94.25%);
}
.fueling-details {
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.social-fields li {
    margin-bottom: 0;
}

.social-fields li>div {
    /* background: rgba(34, 36, 45, 1);
    text-align: center;
    padding: 10px 15px; */
}

.social-fields li>div i {
    display: flex;
    margin-right: 10px;
}

.social-fields li>div h3 {
    background: rgba(34, 36, 45, 1);
    text-align: center;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: var(--FuturaBold);
    letter-spacing: 0.9px;
}

.social-body {
    height: 415px;
    background-color: #ebedf0;
}

@media (max-width: 1366px) {
    .fueling-details h5 span.text-orange {
        font-size: 58px;
        line-height: 55px;
    }

    .fueling-details h5 span.text-white {
        font-size: 60px;
        line-height: 85px;

    }
}

@media (max-width: 991px) {

    .fueling-details h5 span {
        justify-content: center;
    }

    .fueling-details::before {
        width: 100%;
    }

    .fueling-details .heading-style {
        padding: 25px;
        text-align: center;
    }

}

@media (max-width: 767px) {
    .meta-details span {
        font-size: 12px;
    }
}