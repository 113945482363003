/*Fonts*/
@font-face {
	font-family: 'Avenir Roman';
	src: url('./Components/assets/fonts/Avenir-Roman.eot');
	src: url('./Components/assets/fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
		url('./Components/assets/fonts/Avenir-Roman.woff2') format('woff2'),
		url('./Components/assets/fonts/Avenir-Roman.woff') format('woff'),
		url('./Components/assets/fonts/Avenir-Roman.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Heavy';
	src: url('./Components/assets/fonts/Avenir-Heavy.eot');
	src: url('./Components/assets/fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'),
		url('./Components/assets/fonts/Avenir-Heavy.woff2') format('woff2'),
		url('./Components/assets/fonts/Avenir-Heavy.woff') format('woff'),
		url('./Components/assets/fonts/Avenir-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Futura Bold';
	src: url('./Components/assets/fonts/Futura-Bold.eot');
	src: url('./Components/assets/fonts/Futura-Bold.eot?#iefix') format('embedded-opentype'),
		url('./Components/assets/fonts/Futura-Bold.woff2') format('woff2'),
		url('./Components/assets/fonts/Futura-Bold.woff') format('woff'),
		url('./Components/assets/fonts/Futura-Bold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Futura Medium';
	src: url('./Components/assets/fonts/Futura-Medium.eot');
	src: url('./Components/assets/fonts/Futura-Medium.eot?#iefix') format('embedded-opentype'),
		url('./Components/assets/fonts/Futura-Medium.woff2') format('woff2'),
		url('./Components/assets/fonts/Futura-Medium.woff') format('woff'),
		url('./Components/assets/fonts/Futura-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Futura Heavy';
	src: url('./Components/assets/fonts/Futura-Heavy.eot');
	src: url('./Components/assets/fonts/Futura-Heavy.eot?#iefix') format('embedded-opentype'),
		url('./Components/assets/fonts/Futura-Heavy.woff2') format('woff2'),
		url('./Components/assets/fonts/Futura-Heavy.woff') format('woff'),
		url('./Components/assets/fonts/Futura-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}


.toast-position {
	top: 100px !important
}

/*Fonts End Here*/

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: unset !important;
	}
}

/*Colors*/
:root {
	--black: #000000;
	--navbarcolor: #07070A;
	--scrollbackground: #404350;
	--background: #111217;
	--primary: #ff7900;
	--primarylight: #E47007;
	--white: #ffffff;
	--grey: #22242D;
	--lightgrey: #18191e;
	--libackground: rgba(0, 0, 0, 0.4);
	--contentcolor: rgba(255, 255, 255, 0.8);
	--paragraphcolor: rgba(255, 255, 255, 0.7);
	--Lightestwhite: rgba(255, 255, 255, 0.3);
	--lightestgrey: rgba(34, 36, 45, 1);
	--lightestgreyborder: rgba(0, 0, 0, 0.1);
	--bgpurple: rgba(85, 29, 135, 1);
	--secondary: rgba(0, 166, 81, 1);
	--AvenirHeavy: 'Avenir Heavy';
	--AvenirNormal: 'Avenir Roman';
	--FuturaBold: 'Futura Bold';
	--FuturaMedium: 'Futura Medium';
	--FuturaHeavy: 'Futura Heavy';
	--movetop: #001a2e;
}

[data-theme='light'] {
	--background: #ffffff;
	--navbarcolor: #ffffff;
	--black: #ffffff;
	--lightgrey: #5B5B5B;
	--white: #07070A;
	--contentcolor: rgba(0, 0, 0, 1);
	--paragraphcolor: rgba(0, 0, 0, 0.9);
	--Lightestwhite: rgba(0, 0, 0, 0.3);
	--scrollbackground: rgba(255, 255, 255, 0.3);
	--movetop: rgba(255, 255, 255, 0.3);
}
[data-theme='light'] .bg-white{
	background-color: #f1f3f3 !important;
}
[data-theme= 'light'] .btn--primary--outline span {
    color: #000;
}
.themeselect {
	position: fixed;
	top: 80%;
	transform: translateY(-80%);
	right: -40px;
	transform: rotate(-90deg);
	padding: 5px 10px;
	border: 0;
	background-color: var(--white);
	color: var(--black);
	z-index: 999999;
}

:root {
	/* --background: #111217;
	--primary: #ff7900;
	--primarylight: rgb(207 105 15);
	--white: #ffffff;
	--grey: #22242D;
	--lightgrey: #18191e;
	--lightestgrey: rgba(34, 36, 45, 1);
	--secondary: rgba(0, 166, 81, 1);
	--AvenirHeavy: 'Avenir Heavy';
	--AvenirNormal: 'Avenir Roman';
	--FuturaBold: 'Futura Bold';
	--FuturaMedium: 'Futura Medium';
	--FuturaHeavy: 'Futura Heavy'; */
}


.sticky-top {
	position: fixed;
	top: 0;
	z-index: 1020;
	left: 0;
	right: 0;
	width: 100%;
}

[data-theme='light'] .text-black {
	color: #fff !important;
}

[data-theme='light'] .text-white {
	color: #000 !important;
}

.bg-grey {
	background: var(--grey) !important;
	border-color: var(--grey) !important;
}

.bg-purple {
	background: var(--bgpurple) !important;
	border-color: var(--bgpurple) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.fw-500 {
	font-family: var(--AvenirHeavy);
}

/* width */
::-webkit-scrollbar {
	width: 10px;
	height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #404350;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #404350;
}
/* Track */
[data-theme= 'light'] ::-webkit-scrollbar-track {
	background: var(--paragraphcolor);
}

/* Handle */
[data-theme= 'light'] ::-webkit-scrollbar-thumb {
	background: var(--scrollbackground);
}

/* Handle on hover */
[data-theme= 'light'] ::-webkit-scrollbar-thumb:hover {
	background: var(--scrollbackground);
}

/* autoComplete style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	border: 1px solid var(--lightestgrey);
	-webkit-text-fill-color: var(--white);
	-webkit-box-shadow: 0 0 0px 1000px var(--lightestgrey) inset;
	transition: background-color 5000s ease-in-out 0s;
}

/*autoComplete style end here*/
* {
	padding: 0;
	margin: 0;
}

ul {
	padding: 0;
	margin: 0;
	list-style: noen;
}

ul li {
	list-style: none;
	color: var(--paragraphcolor);
	margin-bottom: 20px;
	font-size: 16px;
}

a {
	text-decoration: none;
}

p {
	color: var(--paragraphcolor);
	font-weight: 300;
}

figure {
	margin-bottom: 0;
}

.new-brd.instagram-feed video {
	width:100%;
	}

.form-control {
	background: #ffffff;
	border: 1px solid #d5dae1;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	height: 44px;
}

.form-select:focus,
.form-select:hover,
.form-control:focus,
.form-control:hover {
	border: 1px solid #d5dae1;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.gasitup-t {
	position: relative;
	font-size: 16px;
	background-color: var(--background);
	color: var(--paragraphcolor);
	overflow-x: hidden;
	letter-spacing: 0.01em;
	font-family: var(--AvenirNormal);
}

body.opennav:before {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--background);
	opacity: 0.5;
	z-index: -1;
}

.bg-none {
	background-color: transparent;
}

img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

body.opennav {
	position: relative;
}

.btn {
	cursor: pointer;
	background-color: var(--white);
	position: relative;
	z-index: 0;
	box-shadow: none;
	min-width: 110px;
	overflow: hidden;
}

.btn:hover span{color: #fff;}

.btn span {
	/* color: var(--white); */
	color: #fff;
	display: block;
	transform-origin: center left;
	transition: color 0.3s ease;
	position: relative;
	z-index: 1;
}

.btn em {
	position: absolute;
	height: 1px;
	background: var(--white);
	width: 47%;
	right: 23px;
	top: 50%;
	transform: scaleX(0.25);
	transform-origin: center right;
	transition: all 0.3s ease;
	z-index: 1;
}

.btn:hover span {
	color: #fff;
}

.btn--primary {
	border: 2px solid var(--primary);
	color: #fff;
	background-color: var(--primary);
	font-size: 18px;
	line-height: 44px;
	font-family: var(--AvenirNormal);
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9;
}

.btn--primary--outline {
	border: 1px solid var(--primary);
	color: var(--white);
	background-color: transparent;
	font-size: 18px;
	line-height: 44px;
	font-family: var(--AvenirNormal);
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9;
}

.btn--primary--outline:hover {
	background-color: var(--primary);
	border-color: var(--primary);
}

.btn--primary--outline:hover span {
	color: var(--white);
}

.btn--secondary {
	border: 2px solid var(--secondary);
	color: var(--white);
	background-color: var(--secondary);
	box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
	font-size: 18px;
	line-height: 44px;
	font-family: var(--AvenirNormal);
	height: 44px;
	display: flex;
	align-items: center;
	transition: all ease-in-out 0.5s;
}

.btn--secondary:hover span {
	color: #fff !important;
}

.btn--secondary:hover,
.btn--secondary:focus {
	border: 2px solid var(--secondary);
	color: var(--primary);
	background-color: var(--secondary);
	transition: all ease-in-out 0.5s;
}

.btn--primary:hover,
.btn--primary:focus {
	border: 2px solid var(--primarylight);
	color: #fff !important;
	background: var(--primarylight);
	transition: all ease-in-out 0.5s;
}

.dots-purple {
	position: relative;
}

.dots-purple::before {
	position: absolute;
	content: "";
	background: url(./Components/assets/images/purple-dots.svg) center;
	background-repeat: no-repeat;
	background-size: contain;
	padding: 11%;
	left: 4%;
	bottom: -67px;
	z-index: -1;
}

#iframe1,
.fb-page span {
	width: 100% !important;
}

.right-circle {
	position: relative;
	z-index: 9;
}

.right-circle::after {
	position: absolute;
	content: "";
	background: url(./Components/assets/images/orange-circle.svg) center;
	background-repeat: no-repeat;
	background-size: contain;
	padding: 8%;
	right: -1%;
	top: -4%;
	z-index: -1;
}

.text-orange {
	color: var(--primary) !important;
}

.text-green {
	color: var(--secondary) !important;
}

.bg-purple {
	color: rgba(85, 29, 135, 0.7);
}

.common-section-all {
	padding: 80px 0;
}

.border-20 {
	border-radius: 20px;
}

.home-banner-texter h1 {
	text-transform: uppercase;
	color: var(--white);
	font-family: var(--FuturaBold);
}

[data-theme='light'] .inner-banner.faq .home-banner-texter h1,
[data-theme='light'] .inner-banner.faq a {
	color: #fff;
}

.bg-transparent {
	background-color: transparent;
}

.heading-style {
	margin-bottom: 38px;
}

.heading-style h5 {
	font-size: 34px;
	font-family: var(--FuturaBold);
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
	color: var(--white);
	text-transform: capitalize;
	margin-bottom: 20px;
}

.heading-style h4 {
	font-size: 28px;
	font-family: var(--AvenirNormal);
	letter-spacing: 0.05rem;
	line-height: normal;
	letter-spacing: normal;
	color: var(--contentcolor);
	margin: 20px 0px;
	line-height: 45px;
}

.heading-style p {
	font-size: 16px;
	font-family: var(--AvenirNormal);
	line-height: 29px;
	letter-spacing: 0.01em;
	color: var(--paragraphcolor);
}

.slick-prev:before {
	content: "";
	background: url(./Components/assets/images/arrownext.svg) no-repeat center;
	transform: rotate(180deg);
	background-size: contain;
	width: 25px;
	height: 25px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;

}

.slick-arrow {
	background: rgba(34, 36, 45, 0.8) !important;
	width: 48px;
	height: 44px;
	z-index: 99;
	transition: all ease-in-out 0.5s;
}
[data-theme = 'light'] .slick-arrow{
	background-color: rgb(122 124 129) !important;
}

.slick-arrow:hover {
	background-color: var(--primary) !important;
}

.slick-next:before {
	content: "";
	background: url(./Components/assets/images/arrownext.svg) no-repeat center;
	background-size: contain;
	width: 25px;
	height: 25px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}

.slick-prev {
	left: 0;
}

.slick-next {
	right: 0;
}

.fr .row {
	flex-direction: row-reverse;
}

body .d-lg-block,
.mob-block {
	display: block !important;
}

.desk-block {
	display: none !important;
}

#progress {
	position: fixed;
	bottom: 20px;
	right: 10px;
	height: 60px;
	width: 60px;
	display: none;
	place-items: center;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	z-index: 99;
	border: 5px solid var(--lightgrey);
}

#progress-value {
	display: flex;
	height: calc(100% - 8px);
	width: calc(100% - 8px);
	background-color: var(--lightgrey);
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	color: var(--movetop);
}
[data-theme = 'light'] #progress-value{
	background-color: #fff;
}
[data-theme = 'light'] #progress-value i img{
	filter: invert(1);
}
[data-theme = 'light'] #progress{
	border-color: #d5dae1;
	/* background: conic-gradient(var(--primary) 11%, #7a7c81 11%) !important; */
}
#progress-value i {
	display: flex;
}

.dashboard-listing figure img {
	/* height: 456px;
	object-fit: cover; */
}

.swal2-container.swal2-center>.swal2-popup {
	background-color: var(--lightestgrey);
	padding-bottom: 50px;
	color: #ffffff;
}

.instagram-feed .social-body img {
	width: 100%;
	margin-bottom: 10px;
}

.instagram-feed .social-body svg {
	display: none;
}

.services .head-icon i {
	overflow: hidden;
}

@media (max-width: 1200px) and (min-width: 991px) {
	.dots-purple::before {
		bottom: -43px;
	}
}
@media (max-width: 767px) {
	.mapboxgl-ctrl-top-left .mapboxgl-ctrl{
		width: 90%;
			}	
	.right-circle::after {
		right: 0;
	}

	.dots-purple::before {
		bottom: -52px;
	}

	#progress {
		width: 50px;
		height: 50px;
	}

	.form-control {
		height: 38px;
	}
}

@media (max-width: 480px) {
	.dots-purple::before {
		bottom: -34px;
	}
}
