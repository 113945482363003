.macbook.img-frame .videobg {
    position: absolute;
    top: 20px;
    z-index: 9;
}

.macbook.img-frame .videobg video {
    position: relative;
    top: 0;
    width: calc(100% - 8%);
    height: calc(100% - 12px);
}

.macbook.img-frame:before {
    display: none;
}

.img-frame img {
    width: 100%;
}


@media (max-width: 575px) {
    .macbook.img-frame .videobg {
        top: 10px;
    }
}