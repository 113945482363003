.testimonial-reel .box {
	position: relative;
	margin-bottom: 20px;
}

.custom-tstimonis .thumbnail-image+.title-des {
	margin-left: 0px;
}
.thumbnail-image+.title-des {
	word-break: keep-all;
	/* white-space: pre; */
	margin: 20px auto;
	display: inline-block;
	line-height: normal;
	margin-left: 0px;
}

.thumbnail-image+.title-des small {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: var(--white);
	font-family: var(--AvenirNormal);
}

.thumbnail-image+.title-des h3 {
	font-weight: 900;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	text-transform: capitalize;
	color: var(--white);
	font-family: var(--FuturaHeavy);
}

.thumbnail-image {
	margin: 0px auto 0px;
	text-align: center;
	width: 90px !important;
	height: 90px;
	border: 1px solid #111217;
	border-radius: 50%;
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
}
[data-theme = 'light'] .thumbnail-image{
	border: 1px solid #f1f3f3;
	background-color: #f1f3f3;
}
.thumbnail-image figure {
	padding: 0px 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

}

.thumbnail-image figure img {
	height: auto;
}

.testimonial-reel .box .test-component {
	border-radius: 10px;
	min-height: 130px;
	padding: 20px 45px 0px;
}

.testimonial-reel .box .test-component p {
	max-width: 700px;
	margin: auto;
	color: var(--white);
}

.testimonial-icon i {
	margin-right: 5.4px;
	color: #f8c51c;
	display: inline-block;
}

.testimonial-icon a:last-child i {
	margin-right: 0;
}

.testimonial-icon {
	margin-bottom: 25px;
}

.testimonial-reel .slick-slide.slick-cloned {
	transition: all ease-in-out 0.5s;
}

.testimonial-reel .box .test-title p {
	font-family: var(--AvenirNormal);
	font-style: normal;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: var(--white);
}

.testimonial-reel .box .test-title h4 {
	font-family: var(--AvenirHeavy);
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: var(--white);
}

.testimonial-reel .box .test-content {
	font-family: var(--AvenirNormal);
	line-height: 30px;
	text-align: center;
	color: var(--white);
}

.testimonial-reel .slick-slide {
	text-align: center;
	/* transition: all ease-in-out 0.5s; */
}

.testimonial-reel .slick-slide.slick-current {
	transform: none;
	opacity: 1;
	transition: all ease-in 0.5s;
}

.testimonial-reel .slick-slide {
	transform: scale(0.8);
	/* transition: all ease-in-out 0.5s; */
	opacity: 0.5;
}

.testimonial-reel .slick-slide:not(.slick-current) span {
	opacity: 0;
	visibility: hidden;
	/* transition: all ease-in-out 0.5s; */
}

.testimonial-reel .slick-prev , .testimonial-reel .slick-next{
		top: 30%;
	transform: translateY(-30%);
}
.testimonial-reel .slick-prev {
	left: 0;

}

.testimonial-reel .slider.slider-nav.thumb-image {
	max-width: 450px;
	margin: auto;
}

.testimonial-reel .slider.slider-nav.thumb-image .slick-slider {
	position: static;
}

.testimonial-reel .slick-dots {
	left: 0;
}

.testimonial-reel .slick-dots li button:before {
	display: none;
}

.testimonial-reel .slick-dots li {
	height: 12px;
	width: auto;
}

.testimonial-reel .slick-dots li button {
	width: 25px;
	background-color: var(--primary);
	height: 3px;
	padding: 3px;
	border-radius: 50px;
	transition: all ease-in-out 0.5s;
}

.testimonial-reel .slick-dots li:not(.slick-active) button {
	width: 5px;
	height: 3px;
	background-color: var(--grey);
	transition: all ease-in-out 0.5s;
}
[data-theme = 'light'] .testimonial-reel .slick-dots li:not(.slick-active) button{
	background-color: rgb(122 124 129) !important;
}
@media (max-width: 767px) {

	.testimonial-reel .box .test-component p {
		line-height: 20px;
	}

	.testimonial-icon {
		margin-bottom: 10px;
	}
	.testimonial-reel .box .test-component{
		padding-top: 0;
		padding-left: 30px;
		padding-right: 30px;
	}
	.thumbnail-image+.title-des h3{
		font-size: 16px;
	}
	.thumbnail-image+.title-des small{
		font-size: 12px;
	}
}