.ourprocess.inner-banner.section-banner h1 span {
    font-family: var(--FuturaBold);
}

.section-banner.geofencing.ourprocess {
    padding-top: 45px;
    z-index: 1;
}

.ourprocess.inner-banner.section-banner h1 span.text-orange {
    font-size: 77.1404px;
}

.ourprocess.inner-banner.section-banner h1 span.text-white {
    font-size: 62.1404px;
    line-height: 62px;
    text-transform: uppercase;
    color: #FFFFFF;

}

.ourprocess .ourprocessbanner figure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.ourprocess .iphone-tab .image-slide {
    top: -5px;
}

.ourprocess .iphone-tab {
    position: absolute;
    width: 150px;
    height: 150px;
    top: 44%;
    z-index: 9;
    left: -9%;
}

.ourprocess .ourprocessbanner img:nth-child(2) {
    align-self: flex-start;
    margin-top: 0;
}

.ourprocess .ourprocessbanner img:nth-child(1) {
    align-self: flex-end;
    margin-top: 0;
}

.efficient-delivery .listing-list ul li a,
.efficient-delivery .listing-list ul li {
    position: relative;
}

.efficient-delivery .listing-list ul li a:hover i.white_check::before,
.efficient-delivery .listing-list ul li:hover a i.white_check::before {
    position: absolute;
    content: "";
    background: url(../../assets/images/checkwhite.png) no-repeat center;
    left: 18px;
    width: 30px;
    height: 30px;
    background-size: contain;
}

[data-theme='light'] .listing-list ul li a,
[data-theme='light'] .listing-list ul li {
    background: linear-gradient(90.05deg, rgba(234, 234, 234, 1) 52.98%, rgba(34, 36, 45, 0) 99.96%);
}

[data-theme='light'] .ourprocess.inner-banner.section-banner h1 span.text-white {
    color: #fff !important;
}
[data-theme='light'] .ourprocess .home-banner-texter p {
    /* color: #22242D; */
}

.listing-list ul li a,
.listing-list ul li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: linear-gradient(90.05deg, #22242D 52.98%, rgba(34, 36, 45, 0) 99.96%);
    padding: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: var(--white);
    letter-spacing: 0.01em;
    position: relative;
    z-index: 1;
}

.listing-list ul li a::before {
    background: linear-gradient(90.08deg, #FF7900 52.98%, rgba(34, 36, 45, 0) 99.96%);
    position: absolute;
    content: "";
    left: 0%;
    width: 0;
    opacity: 0;
    visibility: hidden;
    top: 0;
    bottom: 0;
    transition: all ease-in-out 0.5s;
    z-index: -1;
}

.listing-list ul li a:hover::before {
    width: 100%;
    opacity: 1;
    visibility: visible;
}

.listing-list ul li a:hover {
    color: #FFFFFF;
}

.ourprocess.inner-banner figure img {
    object-fit: contain;
    /* width: 100%; */
    margin-top: 20px;
}

.procesupply {
    margin-bottom: 42px;
}

.procesupply h4 {
    position: relative;
    margin-bottom: 30px;
}

.procesupply h4 span {
    background-color: #111217;
    font-family: var(--FuturaBold);
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #fff;

}
[data-theme='light'] .section-banner.geofencing.ourprocess{
    background-color: rgb(72 72 72);
}
[data-theme = 'light'] .procesupply h4::before{
    background-color: var(--lightestgreyborder);
}
.procesupply h4::before {
    position: absolute;
    content: "";
    background-color: #22242D;
    height: 2.5px;
    width: 100%;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
}

.procesupply ul li {
    background-color: #22242D;
    height: 170px;
    border: 1.5px solid var(--primary);
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 25px;
    z-index: 9;
    text-align: center;
    margin-bottom: 0;
    padding: 10px;
}

[data-theme='light'] .procesupply h4 {
    z-index: 1;
}

[data-theme='light'] .procesupply h4 span {
    background-color: #fff;
    color: #000;
}

[data-theme='light'] .procesupply ul li span {
    color: #000;
}
[data-theme='light'] .procesupply ul li, [data-theme='light'] .reinvented-fuel .supplychain ul li .main-img {
    background-color: #fff;
}
[data-theme='light'] .procesupply ul li::before, [data-theme='light'] .reinvented-fuel ul li .main-img::before{
    border-left: 15px solid #fff;
}
.procesupply .supplychain ul li:nth-child(2) {
    width: 60%;
}

.procesupply ul li::before,
.reinvented-fuel .mainimg {
    position: relative;
    margin-bottom: 90px;
}

/* .procesupply ul li::before, */
.reinvented-fuel .mainimg::before {
    position: absolute;
    border-left: 2px dashed rgba(255, 255, 255, 0.4);
    content: '';
    height: 80px;
    width: 0;
    bottom: -80px;
    margin: auto;
    left: 0;
    right: 0;
}

.procesupply ul li::before,
.reinvented-fuel ul li .main-img::before {
    position: absolute;
    content: "";
    left: -3px;
    transform: translateY(-50%);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--grey);
    top: 50%;
    z-index: 1;
}

.procesupply ul li::after,
.reinvented-fuel ul li .main-img::after {
    position: absolute;
    content: "";
    left: -1px;
    transform: translateY(-50%);
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid var(--primary);
    top: 50%;
}

.procesupply ul li:first-child:before,
.procesupply ul li:first-child:after,
.reinvented-fuel ul li:first-child .main-img:before,
.reinvented-fuel ul li:first-child .main-img:after {
    display: none;
}

.procesupply ul li span {
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
    font-weight: var(--AvenirHeavy);
    text-align: center;
    color: #fff;
    display: block;
}

.conprensive-emmision .heading-style h5 {
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    text-transform: capitalize;
    color: var(--white);
    font-family: var(--FuturaMedium);
}

.conprensive-emmision .heading-style h4 {
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    text-transform: capitalize;
    color: #FF7900;
    font-family: var(--FuturaBold);
}

.conprensive-emmision {
    background: #22242D;
    position: relative;
    padding: 50px;
    z-index: 1;
}

.conprensive-emmision::before {
    position: absolute;
    content: "";
    background-color: #1D1F27;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    z-index: -1;
}

[data-theme='light'] .conprensive-emmision {
    background-color: #f1f3f3;
}

[data-theme='light'] .conprensive-emmision::before {
    background-color: #dde1e1;
}

.conprensive-emmision p small,
.conprensive-emmision p small a {
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;

}

.conprensive-emmision p {
    margin-top: 42px;
}

/* [data-theme= 'light'] .conprensive-emmision .text-white{
color: #fff !important;
} */
.conprensive-emmision p small a {
    display: inline-block;
}

.conprensive-emmision ul li {
    font-size: 18px;
    line-height: 24px;
    color: var(--white);
    font-family: var(--AvenirNormal);
    margin-bottom: 10px;
}

.conprensive-emmision ul {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 38px;
    flex-wrap: wrap;
}

.reinvented-fuel .supplychain ul li .main-img {
    background-color: #22242D;
    min-height: 170px;
    height: auto;
    border: 1.5px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 25px;
    z-index: 9;
    text-align: center;
    margin-bottom: 0;
}

.reinvented-fuel .supplychain ul li:nth-child(2) {
    width: 60%;
}

.reinvented-fuel .supplychain ul li {
    width: 20%;
}

.subcontent span.h3 {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    font-family: var(--FuturaBold);
    color: var(--white);
    margin: 15px 0;
}

.reinvented-fuel .supplychain {
    position: relative;
}

.reinvented-fuel .supplychain .sub-img img {
    background-color: #111217;
    padding: 0 10px;
    position: relative;
    z-index: 1;
}
[data-theme='light'] .reinvented-fuel .supplychain .sub-img img {
    background-color: #fff;
}
[data-theme='light'] .reinvented-fuel .supplychain .sub-img img {
    /* background-color: transparent;
    filter: grayscale(100) brightness(0);
    padding: 0 10px; */
}

[data-theme='light'] .reinvented-fuel .mainimg::before {
    border: 1px dashed rgba(0, 0, 0, 0.2);
}

[data-theme='light'] .reinvented-fuel .supplychain .sub-img::before {
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.reinvented-fuel .supplychain .sub-img::before {
    position: absolute;
    content: '';
    height: 0;
    width: 70%;
    border-top: 2px dashed rgba(255, 255, 255, 0.2);
    top: 63%;
    transform: translateY(-63%);
    left: 0;
    right: 0;
    z-index: 0;
    margin: auto;
}

.mainimg h3 {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #FF7900;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 8px 20px;
    width: auto;
    margin: auto;
    display: inline-block;
    position: relative;
    top: 20px;
    z-index: 99;
}

.reinvented-fuel .supplychain ul li:nth-child(2) .main-img img {
    height: 90px;
    position: relative;
    bottom: -20px;
}

.conprensive-emmision .heading-style {
    margin-bottom: 0;
}

.conprensive-emmision .heading-style p {
    margin-bottom: 0;
}


/*speedometer*/
.niddle {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
}

.niddle img:nth-child(2) {
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 0;

}

.niddle img:nth-child(1) {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    transition: 5s;
    transform: translate(-100%, 50%) rotate(-25deg) translate(100%, -50%);

}

@-webkit-keyframes rotate-90-br-cw {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }

    100% {
        -webkit-transform: rotate(117deg);
        transform: rotate(117deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }
}

@keyframes rotate-90-br-cw {
    0% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }

    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }

    100% {
        -webkit-transform: rotate(117deg);
        transform: rotate(117deg);
        -webkit-transform-origin: bottom left;
        transform-origin: bottom left;
    }
}

/* .list_speedometer ul{
    position: absolute;
  } */
  .list_speedometer ul li span{
    position: relative;
    padding-left: 50px;
    padding-right: 50px;
  }
  .list_speedometer ul li span::before{
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: -10px;
    background-color: #FF7900;
    height: 2px;
    width: 100%;
  }
  .list_speedometer ul li:nth-child(1) span::before{

  }
  .list_speedometer ul li span::after{
    position: absolute;
    content: "";
    right: -47px;
    bottom: -21px;
    background-color: #FF7900;
    height: 2px;
    width: 50px;
    transform: rotate(25deg);

  }
.list_speedometer ul li:nth-child(1) {
    position: absolute;
    left: -60%;
    right: 0;
    top: 60%;
}

.list_speedometer ul li:nth-child(2) {
    position: absolute;
    left: -60%;
    right: 0;
    top: 48%;
}

.list_speedometer ul li:nth-child(3) {
    position: absolute;
    left: -60%;
    right: 0;
    top: 36%;
}

.list_speedometer ul li:nth-child(4) {
    position: absolute;
    left: -60%;
    right: 0;
    top: 24%;
}

.list_speedometer ul li:nth-child(5) {
    position: absolute;
    left: 0%;
    right: 0;
    top: 0%;
}

.list_speedometer ul li:nth-child(9) {
    position: absolute;
    right: -60%;
    left: 0;
    top: 60%;
}

.list_speedometer ul li:nth-child(8) {
    position: absolute;
    right: -60%;
    left: 0;
    top: 48%;
}

.list_speedometer ul li:nth-child(7) {
    position: absolute;
    right: -60%;
    left: 0;
    top: 36%;
}

.list_speedometer ul li:nth-child(6) {
    position: absolute;
    right: -60%;
    left: 0;
    top: 24%;
}

/**/

@media (max-width: 1366px) {
    .ourprocess.inner-banner.section-banner h1 span.text-white {
        font-size: 40px;
        line-height: 50px;
    }

    .ourprocess.inner-banner.section-banner h1 span.text-orange {
        font-size: 50px;
        line-height: 50px;
    }
    .ourprocess .iphone-tab{
        width: 114px;
        left: 0;
    }
    .listing-list ul li a,
    .listing-list ul li {
        font-size: 16px;
    }
}

@media (max-width: 991px) and (min-width: 767px) {
    /*      
    .procesupply ul li:nth-child(4)::before, 
    .reinvented-fuel ul li:nth-child(4) .main-img::before{    
        left: auto;
        top: -8px;
        transform: rotate(90deg);
        right: 80px;
    }
    .procesupply ul li:nth-child(4)::after, 
    .reinvented-fuel ul li:nth-child(4) .main-img::after{
        left: auto;
        transform: rotate(90deg);
        top: -7px;
        right: 80px;
    }
    .procesupply ul li:nth-child(5)::before, 
    .reinvented-fuel ul li:nth-child(5) .main-img::before{    
        left: -3px;
        right: -2px;
        transform: rotate(180deg);
    }
    .procesupply ul li:nth-child(5)::after, 
    .reinvented-fuel ul li:nth-child(5) .main-img::after{
        left: -1px;
        right: -1px;
        transform: rotate(180deg);
    } */
}

@media (max-width: 991px) {
    .listing-list ul li a,
    .listing-list ul li {
        font-size: 14px;
    }
    .conprensive-emmision p small,
    .conprensive-emmision p small a {
        font-size: 16px;
        line-height: normal;
    }

    .procesupply ul li span {
        font-size: 14px;
        line-height: 18px;
    }

    .procesupply ul li::after,
    .reinvented-fuel ul li .main-img::after,
    .procesupply ul li::before,
    .reinvented-fuel ul li .main-img::before {
        border-width: 10px;
    }

    .arrowblock {
        flex-wrap: wrap;
        margin-top: 25px;
    }

    .reinvented-fuel .supplychain .sub-img::before {
        display: none;
    }

    .reinvented-fuel ul li .main-img::before {
        top: 100%;
        border-width: 10px;
        left: auto;
        left: 0;
        bottom: 0;
        transform: rotate(90deg);
        right: 0;
        margin: auto;
        z-index: 999;
    }

    .reinvented-fuel ul li .main-img::after {
        top: 101%;
        border-width: 10px;
        left: auto;
        left: 0;
        bottom: 0;
        transform: rotate(90deg);
        right: 0;
        margin: auto;
        z-index: 9;
    }

    .procesupply ul,
    .reinvented-fuel .supplychain ul {
        justify-content: center !important;
    }

    .procesupply ul li,
    .procesupply .supplychain ul li:nth-child(2),
    .reinvented-fuel .supplychain ul li {
        width: 65%;
        margin-bottom: 40px;

    }

    .procesupply ul li:last-child,
    .reinvented-fuel .supplychain ul li:last-child {
        margin-bottom: 0;
    }

    .ourproceschedule .download-schedule {
        margin: 100px 0;
    }

    .procesupply ul li::after {
        border-width: 10px;
        left: 0;
        bottom: 2px;
        transform: rotate(90deg);
        right: 0;
        margin: auto;
        z-index: -1;
        top: auto;
    }

    .procesupply ul li:first-child:before,
    .procesupply ul li:first-child::after {
        display: none;
    }

    .procesupply ul li::before {
        border-width: 10px;
        left: auto;
        left: 0;
        /* bottom: 0; */
        transform: rotate(90deg);
        right: 0;
        margin: auto;
        z-index: 999;
        bottom: 0;
        top: auto;
    }

    .procesupply ul li:last-child img {
        transform: scaleX(-1);
    }

    .ourprocess.inner-banner.section-banner h1 span.text-white {
        font-size: 32px;
        line-height: 40px;
    }

    .ourprocess.inner-banner.section-banner h1 span.text-orange {
        font-size: 40px;
        line-height: 40px;
    }

    .mainimg h3 {
        font-size: 16px;
    }

    .conprensive-emmision .heading-style h4 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 25px;
    }

    .conprensive-emmision .heading-style h5 {
        font-size: 20px;
        line-height: normal;
    }

    .conprensive-emmision ul li {
        font-size: 14px;
    }

    .conprensive-emmision p {
        margin-top: 32px;
    }

    .conprensive-emmision {
        padding: 30px 20px;
    }

    .downloadschedule .listing-list {
        margin-top: 0;
    }

    .procesupply h4 span {
        font-size: 20px;
    }

    .ourprocess .iphone-tab {
        /* position: absolute;
        width: 126px;
        height: auto;
        top: 36%;
        z-index: 9;
        left: -5%; */
        width: 90px;
    }

    .ourprocess .iphone-tab .IphoneSlider.img-frame .image-slide {
        border-radius: 10px;
    }

    .listing-list ul li a i img,
    .listing-list ul li i img {
        width: 20px;
        height: 20px;
    }

    .listing-list ul li a,
    .listing-list ul li {
        font-size: 16px;
        padding: 12px;
    }

}

@media (max-width: 767px) {
    .ourprocess .iphone-tab {
        width: 131px;
    }
    .listing-list ul li a,
    .listing-list ul li {
        font-size: 14px;
    }

    .ourprocess .ourprocessbanner figure {
        justify-content: center;
    }

    .conprensive-emmision ul li i img {
        width: 20px !important;
    }

    .ourprocess.inner-banner.section-banner h1 span.text-white {
        font-size: 32px;
        line-height: 40px;
    }

    .ourprocess.inner-banner.section-banner h1 span.text-orange {
        font-size: 41px;
        line-height: 40px;
    }

    .mainimg h3 {
        font-size: 14px;
    }

    .procesupply ul li {
        width: 100%;
        margin-bottom: 50px;
    }

    .procesupply ul li:first-child:after,
    .reinvented-fuel ul li:first-child .main-img::after,
    .procesupply ul li:first-child:before,
    .reinvented-fuel ul li:first-child .main-img::before {
        display: none;
    }

    .procesupply ul li:nth-child(4),
    .procesupply ul li:nth-child(5) {
        width: 100%;
    }

    .ourproceschedule .download-schedule {
        margin: 20px 0 250px;
        padding: 20px 0;
    }

    .downloadschedule .listing-list {
        padding-top: 0;
    }

    .procesupply h4 span {
        font-size: 18px;
    }

    .ourprocess.inner-banner .home-banner-texter {
        padding-top: 0;
        padding-bottom: 20px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media (max-width: 575px) {
    .ourprocess .iphone-tab {
        width: 110px;
        left: 30px;
    }
    .ourprocess .macbook.img-frame{
        padding: 15px;
    }
}