.leadershipteam.inner-banner .home-banner-texter h1 span.text-orange {
    font-size: 93.6px;
    line-height: 93px;
}

.leadershipteam.inner-banner .home-banner-texter h1 span {
    font-size: 63.75px;
    line-height: 63px;
    text-transform: uppercase;
    color: #FFFFFF;
}
[data-theme='light'] .leadershipteam.inner-banner .home-banner-texter h1 span.text-white{
  color: #FFFFFF !important;
}
.leadershipteam {
    position: relative;
    background: url(../../assets//images//leaderbanner.jpg) no-repeat center;
    background-size: cover;
    width: 100%;
}

.leadershipteam::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #111217;
    opacity: 0.7;

}

.leadershipteamshadow {
    position: relative;
    width: 100%;
    height: 100%;
}

.leadershipteamshadow::before {
    position: absolute;
    content: "";
    background: url(../../assets/images/shadowup.svg) no-repeat;
    background-position: top left;
    background-size: contain;
    padding: 15%;
    top: 0;
    left: 0;
}

.leadershipteamshadow::after {
    position: absolute;
    content: "";
    background: url(../../assets/images/shadowdown.svg) no-repeat;
    background-position: bottom right;
    background-size: contain;
    padding: 20%;
    bottom: 0;
    right: 0;
}
.moving-shapes{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
}
.moving-shapes img:nth-child(1){
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    -webkit-animation: shake-vertical 30s ease-in-out infinite both;
    animation: shake-vertical 30s ease-in-out infinite both;
}
.moving-shapes img:nth-child(2){
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    -webkit-animation: shake-vertical 30s ease-in-out infinite both;
    animation: shake-vertical 30s ease-in-out infinite both;
}
.moving-shapes img:nth-child(3){
    position: absolute;
    top: 5%;
    left: 18%;
    transform: translate(-5% , -18%);
    -webkit-animation: vibrate-1 5s linear infinite both;
	        animation: vibrate-1 5s linear infinite both;
    z-index: 9;
}
@-webkit-keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    80% {
      -webkit-transform: translateY(6.4px);
              transform: translateY(6.4px);
    }
    90% {
      -webkit-transform: translateY(-6.4px);
              transform: translateY(-6.4px);
    }
  }
  @keyframes shake-vertical {
    0%,
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    10%,
    30%,
    50%,
    70% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
    }
    20%,
    40%,
    60% {
      -webkit-transform: translateY(8px);
              transform: translateY(8px);
    }
    80% {
      -webkit-transform: translateY(6.4px);
              transform: translateY(6.4px);
    }
    90% {
      -webkit-transform: translateY(-6.4px);
              transform: translateY(-6.4px);
    }
  }
  
@-webkit-keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  @keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }

  @media (max-width: 991px) {
    .moving-shapes img:nth-child(1), .moving-shapes img:nth-child(2){
      width: 30%;
    }
    .leadershipteamshadow figure img{
      width: 80%;
    }
    .leadershipteam.inner-banner .home-banner-texter h1 span.text-orange{
      font-size: 64px;
      line-height: 22px;
    }
    .leadershipteam.inner-banner .home-banner-texter h1 span{
      font-size: 42px;
    }
    .moving-shapes img:nth-child(3){
      width: 70px;
    }
    .leadershipteamshadow .home-banner-texter{
      padding: 0px !important;
    }
    .leadershipteamshadow figure {
      margin: 20px 0;
    }
    .leadershipteam.inner-banner .home-banner-texter h1 span.text-orange{
      font-size: 54px;
      line-height: 44px;
    }
    .leadershipteam.inner-banner .home-banner-texter h1 span.text-white{
      font-size: 37px;
      line-height: 36px;
    }
    .leadershipbusiness{
      margin-top: -40px;
    }
  }
  @media (max-width: 767px) {
    .executivemembers{
      margin-top: -50px;
    }
    .leadershipservice .common-section-all.services {
      padding-top: 0;
  }
    .common-section-all.services .heading-style{
      margin-bottom: 18px;
  }
    .leadershipteam.inner-banner .home-banner-texter h1 span.text-orange{
      font-size: 54px;
      line-height: 44px;
    }
    .leadershipteam.inner-banner .home-banner-texter h1 span.text-white{
      font-size: 36px;
      line-height: 36px;
    }
   
  }
  @media (max-width: 575px) {
    .leadershipbusiness{
      margin-top: 0px;
    }
  }