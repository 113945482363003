.blog-comment::before,
.blog-comment::after,
.blog-comment-form::before,
.blog-comment-form::after{
    content: "";
	display: table;
	clear: both;
}


.blog-comment ul.blogcomments{
	padding-right: 15px;
	max-height: 500px;
	height: auto;
	overflow-y: auto;
}
.blog-comment ul{
	list-style-type: none;
	padding: 0;

}
/* .getfleetqouteblock {
	display: none;
} */
.blog-comment img{
	opacity: 1;
	filter: Alpha(opacity=100);
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	  	 -o-border-radius: 4px;
			border-radius: 4px;
}

.blog-comment img.avatar {
	position: relative;
	float: left;
	margin-left: 0;
	margin-top: 0;
	width: 65px;
	height: 65px;
    border-radius: 50%;
}

.blog-comment .post-comments{
    border: 0.2px dashed var(--white);
    margin-bottom: 20px;
    margin-left: 85px;
    margin-right: 0px;
    padding: 10px 20px;
    position: relative;
    border-radius: 4px;
    color: #6b6e80;
    position: relative;
}

.blog-comment .meta {
	font-size: 15px;
    font-family: var(--FuturaHeavy);
	color: #aaaaaa;
	padding-bottom: 8px;
	margin-bottom: 10px !important;
	border-bottom: 1px solid rgb(242 242 242 / 28%);
    color: #fff;
}
.blog-comment .meta i{
    float: right;
}
.blog-comment .meta small{
    font-family: var(--AvenirNormal);
}
.blog-comment .meta i a{
    font-family: var(--AvenirNormal);
    color: var(--primary);
}
.blog-comment .meta a{
    color: var(--white);
    font-size: 16px;
}
.blog-comment ul.comments ul{
	list-style-type: none;
	padding: 0;
	margin-left: 85px;
}

.blog-comment-form{
	padding-left: 15%;
	padding-right: 15%;
	padding-top: 40px;
}

.blog-comment h3,
.blog-comment-form h3{
	margin-bottom: 20px;
	font-size: 26px;
	line-height: 20px;
	font-weight: 800;
}
.close-button{
    position: absolute;
    right: -10px;
    top: -10px;
    background: white;
    border: 0;
    padding: 5px;
    color: #000;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}