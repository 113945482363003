.download-schedule {
	/* margin: 200px 0; */
}

.download-schedule .heading-style h4 {
	color: var(--white);
	/* min-height: 100px; */
}
.finger-tips .heading-style h4 {
    line-height: normal;
}
.downlaod-schedule .finger-tips:before {
	background: url(../assets/images/gasitupdownload.jpg) no-repeat center;
	background-size: cover;
	opacity: 1;
}

.fuelscreen {
	/* margin-bottom: -170px; */
}

.download-schedule .fuel-screen {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-bottom: -170px; */
}

.download-schedule .fuel-screen i:first-child {
	/* position: relative;
    left: 0px;
    transform: translate(-50px, -30px);
    top: -30px; */
}

.download-schedule .fuel-screen i:last-child {
	position: absolute;
	right: 0;
	top: -40px;
	transform: translatey(-40px);
}

.download-schedule h4 {
	line-height: normal !important;
}

.download-schedule h4 span {
	font-family: var(--AvenirRoman);
	font-style: normal;
	font-size: 24px;
	color: var(--paragraphcolor);
	line-height: normal;
}


.verticalFlip {
	display: inline;
}

.verticalFlip span {
	animation: vertical 12.5s linear infinite 0s;
	-ms-animation: vertical 12.5s linear infinite 0s;
	-webkit-animation: vertical 12.5s linear infinite 0s;
	color: #ea1534;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	width: 400px;
	padding-right: 30px;
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 35px;
}

.verticalFlip span:nth-child(2) {
	animation-delay: 4s;
	-ms-animation-delay: 4s;
	-webkit-animation-delay: 4s;
}

.verticalFlip span:nth-child(3) {
	animation-delay: 8.5s;
	-ms-animation-delay: 8.5s;
	-webkit-animation-delay: 8.5s;
}

/*Vertical Flip Animation*/
@-moz-keyframes vertical {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 0;
		-moz-transform: rotateX(180deg);
	}

	10% {
		opacity: 1;
		-moz-transform: translateY(0px);
	}

	25% {
		opacity: 1;
		-moz-transform: translateY(0px);
	}

	30% {
		opacity: 0;
		-moz-transform: translateY(0px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes vertical {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 0;
		-webkit-transform: rotateX(180deg);
	}

	10% {
		opacity: 1;
		-webkit-transform: translateY(0px);
	}

	25% {
		opacity: 1;
		-webkit-transform: translateY(0px);
	}

	30% {
		opacity: 0;
		-webkit-transform: translateY(0px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes vertical {
	0% {
		opacity: 0;
	}

	5% {
		opacity: 0;
		-ms-transform: rotateX(180deg);
	}

	10% {
		opacity: 1;
		-ms-transform: translateY(0px);
	}

	25% {
		opacity: 1;
		-ms-transform: translateY(0px);
	}

	30% {
		opacity: 0;
		-ms-transform: translateY(0px);
	}

	80% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@media (max-width: 767px) {
	.download-schedule .fuel-screen i:first-child {
		transform: translate(-30px, -30px);
	}

	.downlaod-schedule i.icon img {
		width: auto;
		height: 70px;
	}

	.downlaod-schedule .finger-tips:before {
			background-size: cover;
	}
}