.navbar {
	background: var(--navbarcolor);
	padding: 10px 0;
	border-bottom: 1.5px solid var(--grey);
	padding-top: 0.5rem !important;
	padding-bottom: 0;
}

.navbar ul li {
	margin-bottom: 0;
}

.w3_megamenu .dropdown a,
.w3_megamenu .dropdown-menu a {
	transition: all ease-in-out 0.5s
}

.w3_megamenu .dropdown-menu>li>a,
.w3_megamenu.fw .dropdown-menu>li>a {
	padding: 20px 0px;
	display: block;
}

li.w3_megamenu-content:hover a {
	padding: 20px 15px;
}

.w3_megamenu .dropdown-menu>li>a.active,
.w3_megamenu.fw .dropdown-menu>li>a.active {
	padding: 20px 15px;
}

.w3_megamenu.fw .dropdown-menu li.w3_megamenu-content:hover>a,
.w3_megamenu.fw .dropdown-menu li.w3_megamenu-content>a.active {
	padding: 20px 15px;
}

.w3_megamenu.fw .dropdown-menu li.w3_megamenu-content>a {
	padding: 20px 0px;
	position: relative;
	display: block;
}

.w3_megamenu .navbar-nav>li>.dropdown-menu {
	margin-top: 1px;
}

.navbar-nav>li a {
	position: relative;
}

.navbar-nav .w3_megamenu a.active:before {
	opacity: 1;

}

.navbar-nav>li>a::before {
	position: absolute;
	content: "";
	left: 0;
	right: 0;
	width: 0%;
	height: 2px;
	background-color: var(--primary);
	margin: auto;
	bottom: 0;
	text-align: center;
	transition: all linear 0.5s;
	opacity: 0;
}

.navbar-nav>li:hover>a::before,
.navbar-nav>li>a.active::before {
	opacity: 1;
	width: 80%;
}

.w3_megamenu i {
	color: #BFBFBF
}

.w3_megamenu .dropdown-menu {
	box-shadow: none;
	border: 0;
	padding: 0;
}

.w3_megamenu .form-control {
	margin-top: 10px;
	border: 1px solid #efefef;
}
.w3_megamenu .btn{
	font-size: 14px;
}
.w3_megamenu .btn.btn--primary {
	background: var(--primary);
	box-shadow: 0px 1px 2px rgb(105 81 255 / 5%);
	border-radius: 6px;
	opacity: 1;
	border: 0;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--AvenirNormal);
}

.w3_megamenu .btn.btn--grey {
	background: var(--grey);
	box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
	border-radius: 6px;
	opacity: 1;
	border: 0;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--AvenirNormal);
	transition: all ease-in-out 0.5s;
}
[data-theme = 'light'] .w3_megamenu .btn.btn--grey{
	background: rgb(34 36 45 / 60%);
}
.w3_megamenu .btn.btn--grey:hover {
	background-color: var(--primary)
}

.w3_megamenu video {
	max-width: 100%;
	height: auto;
}

body .dropdown:hover .dropdown-menu,
body .dropdown a.active .dropdown-menu {
	display: block;
	padding: 15px;
	padding: 15px;
	opacity: 1;
	visibility: visible;
}

.dropdown:hover>a::after {
	transform: rotate(180deg);
}
[data-theme='light'] .dropdown-toggle::after{
	filter: invert(1);
}
.dropdown-toggle::after {
	background-image: url(../assets/images/arrow_back_ios.svg);
	background-repeat: no-repeat;
	background-position: right;
	width: 9px;
	height: 9px;
	border: 0;
	background-size: 9px;
}
.w3_megamenu p {
	font-size: 13px;
	line-height: 20px;
	color: rgb(255, 255, 255, 0.6);
	margin-bottom: 0;
	font-family: var(--AvenirNormal);
}

.w3_megamenu .nav,
.w3_megamenu .collapse,
.w3_megamenu .dropup,
.w3_megamenu .dropdown {
	position: static;
}

.w3_megamenu .half {
	width: auto;
}

.w3_megamenu .container {
	position: relative;
}

.w3_megamenu .dropdown-menu {
	left: auto;
	background-color: var(--grey);
	overflow: hidden;
	right: auto;
	width: 50%;
}
[data-theme='light'] .w3_megamenu .dropdown-menu{
	background-color: #fff;
	box-shadow: inset 0 30px 25px -25px rgb(0 0 0 / 16%), inset 0 0 0 -25px rgb(0 0 0 / 16%), 0 2px 7px 0 rgb(0 0 0 / 16%);
}
[data-theme='light'] .w3_megamenu .w3_megamenu-content a:after{
	background: #f1f3f3;
}
[data-theme='light'] .w3_megamenu p{
	color: var(--paragraphcolor);
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
	position: absolute;
	overflow: hidden;
	right: 0;
	width: 100%;
	max-width: 100%;
	top: 100%;
	left: auto;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
	border-top: 0;
	padding: 25px;
	visibility: hidden;
	opacity: 0;
}

.w3_megamenu .nav.navbar-right .dropdown-menu {
	left: auto;
}

.w3_megamenu .w3_megamenu-content a:after {
	background: linear-gradient(89.9deg, rgba(0, 0, 0, 0.406) 1.37%, rgba(0, 0, 0, 0.504) 100%, rgba(0, 0, 0, 0.035) 100%);
	border-radius: 0px 4px 4px 0px;
	width: 0%;
	opacity: 0;
	transition: all ease-in-out 0.5s;
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	z-index: -1;
}

/* .w3_megamenu .w3_megamenu-content:hover,
.w3_megamenu .w3_megamenu-content.active {
	padding: 0px;
} */
.navbar-nav>li.w3_megamenu.active>a.dropdown-toggle::before,
.navbar-nav>li.w3_megamenu.fw.active>a.dropdown-toggle::before {
	opacity: 1;
	width: 80%;
}

.w3_megamenu .w3_megamenu-content:hover a:after,
.w3_megamenu .w3_megamenu-content a.active:after {
	width: 100%;
	opacity: 1;
}

.w3_megamenu .w3_megamenu-content:hover a::before,
.w3_megamenu .w3_megamenu-content a.active::before {
	opacity: 1;
}

.w3_megamenu .w3_megamenu-content a:before {
	position: absolute;
	content: "";
	left: 0;
	width: 2.31px;
	height: 100%;
	background: var(--primary);
	top: 0;
	bottom: 0;
	opacity: 0;
	transition: all ease-in-out 0.5s;
}

.w3_megamenu .w3_megamenu-content {
	padding: 0px;
	color: #fafafa;
	position: relative;
	transition: all ease-in-out .5s;
}

.w3_megamenu .dropdown.w3_megamenu.fw .dropdown-menu {
	left: 0;
	right: 0;
	width: 100%;
}

.w3_megamenu .dropdown-menu p.title {
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	color: var(--white);
	opacity: 0.6;
	margin-bottom: 20px;
}

.w3_megamenu .w3_megamenu-content .title {
	margin-top: 0px;
	text-transform: capitalize;
	font-size: 16px;
	line-height: 24px;
	color:  var(--white);
	font-family: var(--FuturaHeavy);
	margin-bottom: 5px;
	letter-spacing: 0.8px;
}

li.w3_megamenu:hover a {
	opacity: 1;
}

.w3_megamenu ul {
	list-style: none;
	padding-left: 0px;
}

.nav>li>a {
	position: relative;
	display: block;
	padding: 10px 15px;
	line-height: 24px;
	text-align: center;
	color:  var(--white);
	opacity: 0.6;
	display: flex;
	align-items: center;
	text-transform: capitalize;
	font-family: var(--AvenirNormal);
}

.nav>:hover,
.nav>li>a.active,
.nav>li.active a {
	color:  var(--white);
	opacity: 1;
}

[data-theme='light'] .nav>:hover,
[data-theme='light'] .nav>li>a.active,
[data-theme='light'] .nav>li.active a {
	color:  #000;
	opacity: 1;
}

[data-theme='light'] .nav>li>a{
	color:  #000;
	opacity: 1;
}

.navbar-default .navbar-toggle {
	border-color: #ddd;
}

.w3_megamenu .nav.navbar-right .dropdown-menu {
	left: auto;
	right: 15px;
	width: auto;
	padding: 10px 15px;
}

.navbar-brand i {
	margin-bottom: 0.5rem !important;
}

@media (min-width: 768px) {
	.navbar-toggle {
		display: none;
	}
}

.navbar-toggle {
	position: relative;
	float: right;
	padding: 9px 10px;
	margin-top: 8px;
	margin-right: 15px;
	margin-bottom: 8px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
}

.navbar-expand-lg .navbar-nav {
	order: 2;
}

.navbar-expand-lg .navbar-collapse {
	order: 1;
}

@media(max-width: 1366px) {
	.nav>li>a {
		padding: 10px;
	}
}

@media(min-width: 1024px) {
	.navbar-expand-lg .navbar-nav li:nth-child(2) .dropdown-menu {
		width: 55.5em;
	}

	.navbar-expand-lg .navbar-nav li:nth-child(4) .dropdown-menu {
		width: 46.5rem;
	}

	.navbar ul li {
		padding-bottom: 1.2rem;
		padding-top: 1rem;
	}

}

@media(max-width: 1024px) {
	.navbar-nav>li:hover>a:before, .navbar-nav>li>a.active:before{
		width: 98%;
	}
	.w3_megamenu .dropdown-menu>li>a,
	.w3_megamenu.fw .dropdown-menu>li>a {
		padding: 20px 15px;
	}

	body .dropdown:hover .dropdown-menu,
	body .dropdown a.active .dropdown-menu,
	.w3_megamenu .w3_megamenu-content:hover,
	.w3_megamenu .w3_megamenu-content.active,
	.w3_megamenu .w3_megamenu-content {
		padding: 0 !important;
	}

	.navbar-nav>li.w3_megamenu:hover>a::before {
		display: none;
	}

	.nav>li>a:hover {
		opacity: 0.6;
	}

	.navbar {
		padding: 0;
	}

	.navbar-expand-lg .navbar-collapse {
		display: none !important;
	}

	.navbar-expand-lg .navbar-toggler {
		display: block;
		box-shadow: none;
		outline: none;
		z-index: 999999;
		padding: 0;
	}

	.navbar-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.navbar-header button span {
		filter: invert(1);
	}
	[data-theme = 'light'] .open-nav .navbar-header button span {
		filter: invert(1);
	}
	[data-theme = 'light'] .navbar-header button span {
		filter: none;
	}

	.collapse:not(.show) {
		position: fixed;
		top: 76px;
		right: 0;
		background-color: var(--navbarcolor);
		z-index: 99999;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		transition: all 0.3s linear;
		display: inline-block !important;
		transform: translateX(100%);
		right: -8px;
		opacity: 0;
		visibility: hidden;
	}

	.open-nav .navbar-toggler-icon {
		/* transform: translatex(-220px) !important; */
		background: var(--black);
	}

	.open-nav .collapse:not(.show) {
		transform: none;
		opacity: 1;
		visibility: visible;
	}

	.navbar-brand i {
		justify-content: center;
	}

	.open-nav li {
		position: relative;
		width: 100%;
	}

	.navbar-nav>li.w3_megamenu>a::before,
	.navbar-nav>li.w3_megamenu.fw>a::before {
		/* right: auto;
		left: 20%;
		transform: translateY(-20%);
		bottom: -5px; */
		display: none;
	}

	.open-nav .navbar-toggler-icon {
		background: url(../assets/images/close-line-icon.svg) no-repeat center;
		background-size: 20px;
		filter: none;
		padding: 0px;
	}

	.w3_megamenu .nav.navbar-right .dropdown-menu {
		position: absolute;
	}

	.w3_megamenu .w3_megamenu-content .title {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1px;
	}

	.w3_megamenu .w3_megamenu-content {
		padding: 10px 0;
		margin-bottom: 5px;
	}

	.w3_megamenu .dropdown-menu {
		width: 100%;
	}

	.w3_megamenu .w3_megamenu-content:hover,
	.w3_megamenu .w3_megamenu-content.active {
		padding: 10px 15px;
	}

	.w3_megamenu .w3_megamenu-content br {
		display: none;
	}

	.navbar-toggler {
		order: 1;
	}

	.navbar-expand-lg .navbar-nav {
		order: inherit;
	}

	.navbar-nav>li:hover>a::before,
	.navbar-nav>li.active>a::before {
		width: 100%;
	}

	.navbar-expand-lg .navbar-nav .dropdown-menu {
		position: inherit;
	}

	.dropmenufw {
		margin-bottom: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid var(--primary);
	}

	.w3_megamenu.fw li {
		padding: 10px;
		;
	}

	.w3_megamenu .mr-auto .nav-item.dropdown .w3_megamenu-content {
		padding: 10px !important;
	}
}

@media (max-width: 765px) {
	.navbar-brand img {
		width: 40px;
	}

	.nav>li>a {
		padding: 10px;
		line-height: normal;
	}

	.w3_megamenu .w3_megamenu-content {
		padding: 6px 0;
	}
}