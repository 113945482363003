.footer {
    background: #07070A;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 70px 0 0px;
    overflow: hidden;
}

.footer::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    opacity: 0.4;
    background: url(../assets/images/footerbg.svg) no-repeat bottom;
    background-size: contain;
    top: 0;
    right: 0;
    z-index: -1;
}
[data-theme = 'light'] .reach_us .certified-icon i img{
    filter: none;
}
[data-theme = 'light'] .footer .footer-widget-common ul.footer-links li a::before{
    filter: invert(46%) sepia(90%) saturate(1276%) hue-rotate(359deg) brightness(99%) contrast(107%);
}
[data-theme='light'] .footer .footer-widget-common p a, .footer .footer-widget-common ul li a {
    color: #070707;
}
[data-theme='light'] .reach_us i img{
    filter: invert(46%) sepia(90%) saturate(1276%) hue-rotate(359deg) brightness(99%) contrast(107%);
}
[data-theme = 'light'] .footer_social_links li span a img{
filter: invert(8%) sepia(11%) saturate(1623%) hue-rotate(191deg) brightness(1%) contrast(35%);
}
[data-theme = 'light'] .footer_social_links li span a {
    background-color: var(--lightestgreyborder);
}
[data-theme = 'light'] .footer::before{
    opacity: 0.07;
}
[data-theme = 'light'] .footer{
    background: #f3f3f3;
}
.footer .footer-widget-common p {
    margin-bottom: 12px;
}
.footercertifiedicons.certified-icon i {
    width: 100px;
    height: 100px;
}
.footercertifiedicons.certified-icon i img:hover{
    transform: none;
}
.fc .row {
    margin-top: 0px !important;
}

.fc .row .playstore-icons {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.fc .row .playstore-icons a {
    margin-bottom: 10px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.footer .footer-widget-common h4 {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 12px;
    position: relative;
    display: inline-block;
    margin-bottom: 25px;
}
[data-theme = 'light' ] .footer .footer-widget-common h4, [data-theme = 'light' ] .footer .footer-widget-common ul.footer-links li a{
    color: #000;
}
[data-theme = 'light'] .footer .footer-widget-common h4::before{
    background-color: #000;
}

.footer .footer-widget-common h4::before {
    position: absolute;
    content: "";
    left: 0;
    width: 22px;
    height: 3px;
    background-color:#fff;
    bottom: -4px;
    transition: all ease-in-out 0.8s;
}

.footer .footer-widget-common:hover h4:before {
    width: 100%;
    background-color: var(--primary);
}

.footer .footer-widget-common p a,
.footer .footer-widget-common ul li a {
    font-family: var(--AvenirNormal);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #D1D5DB;
    transition: all ease-in-out 0.5s;
    position: relative;
}

/* .footer .footer-widget-common ul li.footer-links a:hover{
    padding-left: 25px;
} */
/* .footer .footer-widget-common ul.footer-links li:nth-child(1) a::before{
    background: url(../assets/images/upcomming_location.svg) no-repeat center;
    background-size: contain;
} */
/* .footer .footer-widget-common ul.footer-links li:nth-child(2) a::before {
    background: url(../assets/images/drop.png) no-repeat center;
    background-size: contain;
    width: 12px;
    height: 12px;

} */
/* .footer .footer-widget-common ul.footer-links li:nth-child(3) a::before {
    background: url(../assets/images/cui_location.svg) no-repeat center;
    background-size: contain;
    
} */
.footer .footer-widget-common ul.footer-links li a::before {
    position: absolute;
    content: "";
    /* background-color: var(--white); */
    background: url(../assets/images/drop.png) no-repeat center;
    background-size: contain;
    width: 10px;
    height: 10px;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    transition: all ease-in-out 0.5s;
    opacity: 1;
    visibility: visible;
}

.footer .footer-widget-common ul.footer-links li a {
    color: #fff;
    transition: all ease-in-out 0.5s;
    padding-left: 15px;
}

.footer .footer-widget-common ul.footer-links li:hover a {
    padding-left: 20px;
    color: var(--primary);
}

.footer .footer-widget-common ul.footer-links li:hover a::before {
    opacity: 1;
    visibility: visible;
    transition: all ease-in-out 0.5s;
}

.footer .footer-widget-common ul.footer-links {
    /* columns: 2; */
}

.reach_us p {
    margin-bottom: 28px;
}

.reach_us p a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.reach_us p a:hover i {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.reach_us i {
    margin-right: 15px;
    display: block;
}

.footer_social_links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px;
    flex-wrap: wrap;
}

.footer_social_links li a {
    background-color: rgba(255, 255, 255, 0.1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: all ease-in-out 0.5s;
}

.footer_social_links li a i {
    display: flex;
    margin-right: 0;
}

.footer_social_links li a img {
    height: 13px;
    filter: brightness(0) invert(1)
}

.footer_social_links li:hover a {
    background-color: rgb(255, 255, 255);
}

.footer_social_links li:hover a img {
    filter: none;
}
[data-theme = 'light'] .footer_social_links li:hover a i img{
    filter: grayscale(41) invert(1) brightness(10.5);
}
[data-theme = 'light'] .footer_social_links li:hover a{
    background-color: var(--primary);
}
.foote-copyrights p {
    font-family: var(--AvenirNormal);
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--contentcolor);
    margin-top: 10px;
    margin-bottom: 0;
}

#scroll-top {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 50px;
    color: var(--white);
    background-color: var(--primary);
    cursor: pointer;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    transition: all .5s ease-in-out;
    z-index: 3;
}

#scroll-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

#scroll-top::before {
    content: "";
    position: absolute;
    border: 2px dashed rgba(255, 255, 255, .4);
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 50px;
    -webkit-animation: rotate-center 5s linear infinite;
    animation: rotate-center 5s linear infinite
}

@-webkit-keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (min-width: 991px) {
    .footer .col-lg-2{
        width: 20%;
        max-width: 20%;
    }
}
@media (max-width: 1200px) {
    .footer .footer-widget-common p a, .footer .footer-widget-common ul li a{
        font-size: 12px;
    }
    .footer .footer-widget-common h4{
        font-size: 18px;
    }
}
@media (max-width: 991px) {
    .reach_us i {
        margin-right: 10px;
    }

    .footer {
        padding-top: 40px;
    }
   
}

@media (max-width: 575px) {
    .fc .row .playstore-icons a {
        margin-left: inherit !important;
        margin-right: 15px !important;
    }

    .fc .row.playstoreIcons {
        margin-bottom: 0;
    }
    .fc .row .playstore-icons {
        flex-direction: row;
        
    }
}