.circlelo{
    background-color: var(--white);
    border-radius: 20px;
    min-width: 130px;
    padding: 15px 25px;
    text-align: center;
    margin: auto;
    background: #FFFFFF;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 20px;

}
.business-partner .circlelo img{
    width: 100%;
    object-fit: scale-down;
}
.business-partner .slider--container{
    width: 100%;
    position: relative;
    margin-bottom: 0;
}
.business-partner .heading-style span.text-white{color: var(--primary);}
.business-partner .heading-style h5{
    color: #000;
    margin-bottom: 0;
}
.business-partner{
    background: #FFFFFF;
}
[data-theme='light'] .business-partner{
background: #EAEAEA;
}
[data-theme='light'] .business-partner .text-orange{
    /* color: #ffffff !important; */
}
.business-partner .slick-slide > div{
    display: flex;
    text-align: center;
    background: white;
    margin: 0 20px;
    border-radius: 20px;
}
.business-partner .slick-slide {

  }
  
  @media (max-width: 767px) {
    .circlelo{
        padding: 10px 15px;
    }
  }