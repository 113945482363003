.section-banner {
    position: relative;
    overflow: hidden;
    z-index: 9;
    margin-top: 88px;
}

.section-banner figure img {
    object-fit: contain;
    object-position: bottom;
}

.home-banner {
    padding: 30px 0;
    overflow: inherit;
}

.img-frame .image-slide .circlelo {
    padding: 6px 10px;
    border-radius: 0;
}

.img-frame .image-slide .slick-slide {
    margin: 0;
    border-radius: 0;
}

.home-tab .laptopslide.img-frame .image-slide {
    width: calc(100% - 23%);
    height: calc(100% - 0px);
    top: 36px;

}

.img-frame iframe,
.img-frame video,
.img-frame .image-slide {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    width: calc(100% - 22%);
    height: calc(100% - 0px);
    z-index: -1;
    margin: auto;
}

.image-out {
    position: absolute;
    right: 0px;
    left: inherit;
    width: calc(100% - 50%);
    z-index: 99;
}

.image-out img {
    width: 100%;
}

.image-out img.mobile-rental {
    width: 150px;
    position: absolute;
    left: -2%;
    bottom: 10px;
}

@media (min-width: 2250px) {
    .image-out img.mobile-rental {
        width: 170px;
        position: absolute;
        left: 0%;
        bottom: 21px;
    }

}

@media (max-width: 1440px) {

    .img-frame iframe,
    .img-frame video,
    .img-frame .image-slide {
        top: 35px;
    }

    .image-out img.mobile-rental {
        width: 150px;
    }
}


@media (max-width: 1366px) {

    .img-frame iframe,
    .img-frame video,
    .img-frame .image-slide {
        top: 16px;
    }

    .image-out img.mobile-rental {
        width: 18%;
        position: absolute;
        bottom: 20px;
    }
}

@media (max-width: 1024px) {

    .img-frame iframe,
    .img-frame video,
    .img-frame .image-slide {
        top: 54px;
        width: calc(100% - 27%);
    }
}

@media (max-width: 767px) {
    .image-out img.mobile-rental {
        left: 20px;
    }
}

@media (max-width: 500px) {

    .img-frame iframe,
    .img-frame video,
    .img-frame .image-slide {
        top: 43px;
    }

}

.home-tab {
    position: relative;
}

.home-tab:before {
    position: absolute;
    content: "";
    background: url(../assets/images/shadow.png) no-repeat center;
    background-size: contain;
    width: 50%;
    height: auto;
    left: 0%;
    right: 0;
    padding: 50%;
    z-index: -1;
    top: -20%;
    transform: translateY(-20%);
}

.home .home-banner-texter h1 {
    color: var(--white);
    font-size: 33px;
    line-height: normal;
    margin-bottom: 12px;
    font-family: var(--FuturaBold);
    text-transform: uppercase;
}

.home .home-banner-texter h1 span {
    color: var(--white);
    font-size: 59px;
    line-height: 66px;
    text-transform: uppercase;
    font-family: var(--FuturaBold);
}

.home-banner-texter p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 55px;
    color: #fff;
}

[data-theme='light'] .home .home-banner-texter p {
    color: #2d2d2d;
}

.section--banner .container>.row {
    height: calc(100vh - 50px);
}

.truckmove {
    position: absolute;
    right: inherit;
    left: 0;
    top: -35px;
    bottom: 0px;
    margin: auto;
    padding: 0px;
    z-index: -1;
    width: 61%;
    height: auto;

}

.truckmove video {
    width: 100%;
    opacity: 0.8;
}

[data-theme='light'] .truckmove video {
    opacity: 0.9;
}

@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.81);
        transform: scale(0.81);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.88);
        transform: scale(0.88);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.77);
        transform: scale(0.77);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.81);
        transform: scale(0.81);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.88);
        transform: scale(0.88);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.77);
        transform: scale(0.77);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.heading-style h5.newsletter_text {
    text-transform: unset;
}

@-webkit-keyframes Movetruck {
    0% {
        left: 40%;
        bottom: 24%;
        transform: rotate(0deg);

    }

    10% {
        left: 35%;
        bottom: 38%;
        transform: rotate(-10deg);
    }

    20% {
        left: 35%;
        bottom: 38%;
        transform: rotate(-61deg);
    }

    30% {
        left: 24%;
        bottom: 35%;
        transform: rotate(-61deg);
    }

    40% {
        left: 24%;
        bottom: 35%;
        transform: rotate(49deg);
    }

    50% {
        left: 25%;
        bottom: 39%;
        transform: rotate(49deg);
    }

    60% {
        left: 25%;
        bottom: 39%;
        transform: rotate(-51deg);
    }

    70% {
        left: -0.5%;
        bottom: 43%;
        transform: rotate(-51deg);
    }

    80% {
        left: -0.5%;
        bottom: 43%;
        transform: rotate(44deg);
    }

    90% {
        left: 0.8%;
        bottom: 58%;
        transform: rotate(44deg);
    }

    95% {
        left: 0.8%;
        bottom: 58%;
        transform: rotate(-46deg);
    }

    100% {
        left: -12.2%;
        bottom: 60%;
        transform: rotate(-46deg);
    }
}

@keyframes Movetruck {
    0% {
        left: 40%;
        bottom: 24%;
        transform: rotate(0deg);

    }

    10% {
        left: 35%;
        bottom: 38%;
        transform: rotate(-10deg);
    }

    20% {
        left: 35%;
        bottom: 38%;
        transform: rotate(-61deg);
    }

    30% {
        left: 24%;
        bottom: 35%;
        transform: rotate(-61deg);
    }

    40% {
        left: 24%;
        bottom: 35%;
        transform: rotate(49deg);
    }

    50% {
        left: 25%;
        bottom: 39%;
        transform: rotate(49deg);
    }

    60% {
        left: 25%;
        bottom: 39%;
        transform: rotate(-51deg);
    }

    70% {
        left: -0.5%;
        bottom: 43%;
        transform: rotate(-51deg);
    }

    80% {
        left: -0.5%;
        bottom: 43%;
        transform: rotate(44deg);
    }

    90% {
        left: 0.8%;
        bottom: 58%;
        transform: rotate(44deg);
    }

    95% {
        left: 0.8%;
        bottom: 58%;
        transform: rotate(-46deg);
    }

    100% {
        left: -12.2%;
        bottom: 60%;
        transform: rotate(-46deg);
    }
}

/*video block*/
.video_block {
    position: relative;
    z-index: 1;
    height: auto;
    overflow: hidden;
    border-radius: 30px;
}

.video_block video {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: cover;
    min-height: 490px;

}

.videoblk .video_block::before {
    border-radius: 0;
}

.video_block img {
    border-radius: 30px;
    overflow: hidden;
    min-width: 280px;
    min-height: 280px;
    object-fit: cover;
}

.video_block .ecomerce_video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    margin: auto;
    display: flex;
}

.video_block figure {
    margin-bottom: 0;
}

.video_block .styles_playButton__1Q3EF {
    background-color: var(--primary);
    height: 80px;
    position: absolute;
    border-radius: 50%;
}

p.video-btn {
    color: var(--primary);
    width: 70px;
    height: 70px;
    border: 2px solid var(--primary);
    display: flex;
    flex-wrap: wrap;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}

.videoblk {
    position: relative;
}

.videoblk .heading-style {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin: auto;
}

@media (min-width: 992px) {
    .videoblk .heading-style {
        max-width: 960px;
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .videoblk .heading-style {
        max-width: 1140px;
        width: 100%;
    }
}

@media (min-width: 1440px) {
    .videoblk .heading-style {
        max-width: 1320px;
        width: 100%;
    }
}

.videoblk .video_block {
    border-radius: 0;
}

.video_block .styles_playButton__1Q3EF::before {
    content: "";
    border: 5px solid var(--black);
    width: 70px;
    height: 70px;
    position: absolute;
    margin: auto;
    border-radius: 50%;
    border-left: 5px solid transparent;
    animation: spin 4s linear infinite;
    backface-visibility: hidden;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

[data-theme='light'] .videoblk .heading-style h4,
[data-theme='light'] .videoblk .heading-style h5,
[data-theme='light'] .videoblk .heading-style p {
    color: #fff;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*earth*/
.optimumlogos {
    display: flex;
    align-items: center;
    justify-content: center;
}

.optimumlogos li:last-child::before {
    display: none;
}

.optimumlogos li:before {
    position: absolute;
    content: "";
    background-color: var(--libackground);
    height: 70%;
    width: 1px;
    margin: auto;
    right: 0;
    top: 0;
    bottom: 0;
}

.optimumlogos li img {
    height: 42px;
    object-fit: contain;
    max-width: 175px;
    width: 100%;
}

.optimumlogos li {
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    /* background: var(--libackground); */
    background: rgb(91 91 91 / 38%);
    margin-bottom: 0;
}

[data-theme='light'] .optimumlogos li:before {
    /* background-color: transparent; */
}

[data-theme='light'] .optimumlogos li:last-child {
    border-right: 1px solid var(--libackground);
}

[data-theme='light'] .optimumlogos li:first-child {
    border-left: 1px solid var(--libackground);
}

[data-theme='light'] .optimumlogos li {
    background-color: transparent;
    border-top: 1px solid var(--libackground);
    border-bottom: 1px solid var(--libackground);
}

.optimumlogos li:last-child {
    border-radius: 0 10px 10px 0;
}

.optimumlogos li:first-child {
    border-radius: 10px 0px 0px 10px;
}

.optimun-productivity {
    position: relative;
}

.optimun-productivity::before {
    position: absolute;
    content: "";
    background: url(../assets/images/earth.png) no-repeat center;
    background-size: cover;
    padding: 25%;
    bottom: 0%;
    transform: translateY(-15%);
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 1;
}

@keyframes shake-tl {

    0%,
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    10% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg);
    }

    20%,
    40%,
    60% {
        -webkit-transform: rotate(-2deg);
        transform: rotate(-2deg);
    }

    30%,
    50%,
    70% {
        -webkit-transform: rotate(2deg);
        transform: rotate(2deg);
    }

    80% {
        -webkit-transform: rotate(-1deg);
        transform: rotate(-1deg);
    }

    90% {
        -webkit-transform: rotate(1deg);
        transform: rotate(1deg);
    }
}

.finger-tips:before {
    background-color: rgba(85, 29, 135, 1);
    background: linear-gradient(319.35deg,
            rgba(85, 29, 135, 0.1) 5.57%,
            rgba(85, 29, 135, 0.73) 51.88%,
            rgba(85, 29, 135, 0.16) 94.25%);
    opacity: 0.5;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
}

.finger-tips {
    position: relative;
    border-radius: 80px;
    overflow: hidden;
    z-index: 9;
}

.finger-tips ul {
    padding-left: 0;
    margin-top: 35px;
}

.finger-tips ul li {
    padding-left: 35px;
    position: relative;
    margin-bottom: 30px;
    font-family: var(--AvenirNormal);
}

.finger-tips ul li::before {
    background: url(../assets/images/point-icon.svg) no-repeat center;
    content: "";
    position: absolute;
    left: 0;
    padding: 12px;
    background-size: 20px;
}

.finger-tips .btn--primary {
    display: inline-flex;
    line-height: normal;
}

/*business-customer*/
.business-customer h3 {
    color: var(--primary);
    font-family: var(--FuturaBold);
    font-size: 24px;
    margin-bottom: 33px;
    padding-bottom: 33px;
    letter-spacing: 2px;
    border-bottom: 1px solid var(--Lightestwhite);
}

.business-customer h3 i {
    margin-right: 18px;
    display: inline-block;
}

.business-customer ul li {
    position: relative;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: normal;
    color: var(--white);
}

.business-customer ul li::before {
    position: absolute;
    left: 0px;
    content: "";
    background: url(../assets/images/drop.png) no-repeat center;
    background-size: contain;
    padding: 5px;
    top: 8px;
}

[data-theme='light'] .business-customer ul li::before {
    filter: invert(46%) sepia(90%) saturate(1276%) hue-rotate(359deg) brightness(99%) contrast(107%);
}

.business-block {
    position: relative;
}

.business-block::before {
    position: absolute;
    content: "";
    right: -40px;
    height: 100%;
    width: 1px;
    background-color: var(--Lightestwhite);
}

/*download-schedule*/
.bg-orange {
    background-image: linear-gradient(89.7deg, var(--primary) 17.58%, var(--primary) 67.6%);
}

/*intro-sistercompny*/
.charituplist li {
    transition: all ease-in-out 0.5s;
}

.charituplist li:hover figure {
    background-color: var(--secondary);
}

.charituplist li span {
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    font-family: var(--FuturaBold);
    color: #ffffff;
    letter-spacing: 1px;
}

.charituplist li figure {
    background-color: var(--lightgrey);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 15px;
    transition: all ease-in-out 0.5s;
}

[data-theme='light'] .charituplist li span {
    color: var(--contentcolor);
}

[data-theme='light'] .charituplist li figure {
    background: #f3f3f3 !important;
    border: 0;
}

[data-theme='light'] .charituplist li:hover figure {
    background: var(--secondary) !important;
    border: 0;
}

[data-theme='light'] .charituplist li:hover figure span {
    color: #fff;
}

[data-theme='light'] .charituplist li:hover figure img {
    filter: none;
}

[data-theme='light'] .charituplist li img {
    filter: invert(1);
}

[data-theme='light'] .charituplist li figure {
    border: 0;
    background: transparent;
}

[data-theme='light'] .charituplist li figure i img {
    filter: invert(1);
}

.charituplist li i {
    margin-bottom: 15px;
    height: 70px;
}

.charituplist li img {
    transition: all ease-in-out 0.5s;
}

.charituplist li:hover i img {
    transform: scale(1.1);
    transition: all ease-in-out 0.5s;
}

.horn-left img:nth-child(1),
.horn-right img:nth-child(1) {
    margin-bottom: 22px;
}

.horn-left img:nth-child(2),
.horn-right img:nth-child(2) {
    margin-bottom: 22px;
}

[data-theme='light'] .intro-sistercompny .heading-style h5 span {
    opacity: 1;
}

[data-theme='light'] .intro-sistercompny h5 img {
    filter: brightness(20.5) invert(1) hue-rotate(149deg);
}

.intro-sistercompny .heading-style h5 {
    color: var(--secondary);
    margin-bottom: 0;
}

.intro-sistercompny .heading-style {
    margin-bottom: 32px;
}

.intro-horn {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro-horn h5 span {
    font-size: 36px;
}

.intro-horn span {
    font-size: 26px;
    line-height: normal;
    text-align: center;
    letter-spacing: 2px;
    color: var(--white);
    font-family: var(--FuturaBold);
    opacity: 1;
}

/**/
.bg-purple {
    background: var(--bgpurple);
}

.newsleter_signup {
    position: relative;
    z-index: 1;
}

.newsleter_signup h5 {
    margin-bottom: 14px;
}

.newsleter_signup .btn {
    min-width: auto;
}

.newsleter_signup .container::before {
    position: absolute;
    content: "";
    background: url(../assets/images/dots_light.svg) no-repeat center;
    background-size: contain;
    padding: 2% 5%;
    left: 10%;
    top: 15%;
    z-index: -1;
}

.newsleter_signup .container::after {
    position: absolute;
    content: "";
    background: url(../assets/images/dots_light.svg) no-repeat center;
    background-size: contain;
    padding: 2% 5%;
    right: 10%;
    bottom: 13%;
    z-index: -1;
}

.newsleter_signup .heading-style h4 {
    font-size: 20px;
    margin: 0;
    opacity: 0.5;
}

.newsleter_signup .heading-style {
    margin-bottom: 0;
}

[data-theme='light'] .newsleter_signup .heading-style h5,
[data-theme='light'] .newsleter_signup .heading-style h4,
[data-theme='light'] .newsleter_signup .heading-style p {
    color: #fff;
}

/*inner banner*/
[data-theme='light'] .inner-banner {
    background-color: rgba(210, 210, 210, 0.2);
}

[data-theme='light'] .build-pump::before {
    z-index: 1;
    /* opacity: 0.2; */
}

.inner-banner .video_block img,
.inner-banner .video_block:before {
    border-radius: 10px;
}

.businessfleet.inner-banner .video_block img,
.businessfleet.inner-banner .video_block:before {
    border-radius: 0 !important;
}

.section-banner .container>.row {
    height: 100%;
}

.inner-banner .arrow-curve:before {
    left: 20%;
    right: 0;
    padding: 45px;
    bottom: -100px;
    display: block;
}

.fueling-fingertipsslider figure {
    width: 290px;
    height: 584px;
}

.fueling-fingertipsslider figure .IphoneSlider.img-frame .image-slide {
    top: 10px;
}

.newsleter_text {
    text-transform: unset !important;
}

@media (max-width: 1366px) {
    .home-tab .laptopslide.img-frame .image-slide {
        top: 24px;
    }
}

@media (min-width: 1366px) {
    .section-banner .container>.row {
        padding: 3em 0;
    }

    .videoblk .heading-style p {
        font-size: 20px;
    }

    .videoblk .heading-style h4 {
        font-size: 38px;
        margin: 10px 0px 30px;
    }

    .videoblk .heading-style h5 {
        font-size: 50px;
    }
}

@media (max-height: 991px) {
    .intro-sistercompny .heading-style h5 {
        margin-bottom: 25px;
    }

    .section-banner {
        height: auto;
        overflow: hidden;
    }

    .image-out {
        left: 50%;
        width: calc(100% - 55%);
        right: inherit;
    }
}

@media (min-width: 1024px) {
    .section-banner {
        height: calc(100vh - 84px);
        align-items: center;
        display: flex;
    }

    .home-banner-texter h1 {
        font-size: 48px;
    }

    .download-schedule .heading-style h4 {
        width: calc(100% - 45%);
    }
}

@media (max-width: 1440px) {
    .newsleter_signup .heading-style h4 {
        font-size: 18px;
    }

    .home .home-banner-texter h1 {
        font-size: 22px;
        margin-bottom: 0;
    }

    .home .home-banner-texter h1 span {
        font-size: 36px;
        line-height: normal;
    }

    .home .home-banner-texter p {
        margin-bottom: 18px;
        font-size: 18px;
    }
}

@media (max-width: 1366px) {
    .home .home-banner-texter h1 {
        font-size: 20px;
        margin-bottom: 0;
    }

    .home .home-banner-texter h1 span {
        font-size: 34px;
        line-height: normal;
    }

    .home .home-banner-texter p {
        margin-bottom: 18px;
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .section-banner .container {
        /* overflow: inherit; */
        padding: 3em 15px;
    }

    .fueling-fingertipsslider figure {
        max-width: 100%;
        height: auto;
        margin: auto;
    }

    .image-out {
        position: relative;
        left: 0px;
        right: 0px;
        max-width: 500px;
        margin: auto;
        width: 100%;
        padding: 15px;
    }

    .intro-sistercompny h5 br {
        display: block;
    }

}

@media (max-width: 991px) {
    .optimumlogos {
        margin-bottom: 25px;
    }

    .optimumlogos li img {
        height: 33px;
    }

    .section-banner figure img {
        height: auto;
    }

    [data-theme='light'] .business-block,
    [data-theme='light'] .consumer-block {
        background: #f1f3f3;
    }

    .business-block,
    .consumer-block {
        position: relative;
        background: var(--lightgrey);
        padding: 25px;
        margin-bottom: 25px;
    }

    .business-block::before {
        display: none;
    }

    .home-banner-texter {
        text-align: center;
    }

    .intro-horn h5 span {
        font-size: 25px;
    }

    .home-banner:before {
        width: 100%;
        height: 100%;
        padding: auto;
        background-size: cover;
    }

    .finger-tips {
        border-radius: 20px;
        padding-top: 30px;
    }

    .finger-tips .arrow-right::before {
        display: none;
    }

    .download-schedule .fuel-screen img {
        height: 450px;
    }

    .video_block video {
        min-height: 390px;
    }
}

@media (max-width: 767px) {
    .optimumlogos {
        margin-bottom: 25px;
    }

    .optimumlogos li img {
        height: 23px !important;
    }

    .optimumlogos li {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .home-tab .laptopslide.img-frame .image-slide {
        top: 15px;
    }

    .fueling-fingertipsslider figure {
        width: 240px;
    }

    .fueling-fingertipsslider figure .IphoneSlider.img-frame .image-slide {
        top: 0;
    }

    .download-schedule {
        margin: 50px 0 250px;
        padding: 50px 0;
    }

    .video_block .styles_playButton__1Q3EF,
    .video_block .styles_playButton__1Q3EF::before {
        width: 50px;
        height: 50px;
    }

    .video_block .styles_playButton__1Q3EF {
        margin-left: -20px;
    }

    .finger-tips ul li {
        margin-bottom: 20px;
    }

    .finger-tips {
        padding-top: 0;
    }

    .business-block img,
    .consumer-block img {
        width: 50px;
        object-fit: contain;
    }

    .intro-horn span {
        font-size: 20px;
    }

    .business-customer h3 {
        font-size: 20px;
        margin-bottom: 23px;
        padding-bottom: 23px;
    }

    .business-customer ul li br {
        display: none;
    }

    .home .home-banner-texter h1 {
        font-size: 17px
    }

    .home .home-banner-texter h1 span {
        font-size: 30px;
        line-height: 40px;
    }

    .newsleter_signup::before,
    .newsleter_signup::after {
        padding: 5% 16%;
    }

    .download-schedule .bg-orange {
        padding: 70px 0;
        margin: 10px 0 50px;
    }

    .download-schedule .fuel-screen {
        margin: 90px 0 -100px;
    }

    .video_block .ecomerce_video a,
    .video_block .ecomerce_video a:after {
        width: 50px;
        height: 50px;
    }

    .intro-sistercompny {
        padding-bottom: 0;
    }

    .download-schedule .fuel-screen img:first-child {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0;
        position: relative;
        left: 0px;
        bottom: -40px;
    }

    .download-schedule .fuel-screen i img:last-child {
        /* position: absolute;
        right: 50px;
        top: 10px; */
    }

    .download-schedule .heading-style h4 {
        min-height: 50px;
    }

    .home-banner-texter p.banner-parag {
        line-height: normal;
        font-size: 16px;
        margin-top: 15px;
    }

    .home-banner-texter p br {
        display: none;
    }

    .intro-horn {
        margin-bottom: 12px;
    }

    .intro-sistercompny .heading-style h4 {
        margin: 10px 0;
    }

    .horn-left img:nth-child(1),
    .horn-right img:nth-child(1) {
        margin-bottom: 15px;
    }

    .horn-left img:nth-child(2),
    .horn-right img:nth-child(2) {
        margin-bottom: 15px;
        width: 16px;
    }

    .business-block,
    .consumer-block {
        position: relative;
        background: var(--lightgrey);
        padding: 15px 10px;
        margin-bottom: 15px;
    }
}

@media (max-width: 575px) {
    .download-schedule {
        margin: 30px 0 250px;
        padding: 20px 0;
    }

    .download-schedule h4 span {
        font-size: 20px;
    }
}