.calcu-wrapper:after {
    background: linear-gradient(319.35deg,
            rgba(85, 29, 135, 0.1) 5.57%,
            rgba(85, 29, 135, 0.73) 51.88%,
            rgba(85, 29, 135, 0.16) 94.25%);
    opacity: 0.5;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}
[data-theme = 'light'] .calcu-wrapper:after{
    background: #d9dddd;
}
.calcu-wrapper {
    border-radius: 80px;
    position: relative;
    overflow: hidden;
    padding: 70px 60px 40px;
    z-index: 1;
}

#output {
    margin-top: 70px;
    color: var(--primary);
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    position: relative;
    font-size: 14px;
    padding: 4px 15px;
    font-family: var(--AvenirHeavy);
}

#output::before {
    position: absolute;
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -4px;
    left: 50%;
    margin: auto;
    transform: translateX(-50%);
}

:focus-visible {
    outline: none !important;
}

.wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 100%;
    height: 5.25em;
    font: 1em/1 arial, sans-serif;
}

[type="range"] {
    outline: none;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--val) - var(--min)) / var(--range));
    --sx: calc(0.5 * 1.5em + var(--ratio) * (100% - 1.5em));
    margin: 0;
    padding: 0;
    width: 100%;
    height: 1.5em;
    background: transparent;
    font: 1em/1 arial, sans-serif;
}

[type="range"],
[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
}

[type="range"]::-webkit-slider-runnable-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.2em;
    background: rgb(255 255 255 / 19%);
}

.js [type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(var(--primary), var(--primary)) 0 / var(--sx) 100% no-repeat rgb(255 255 255 / 19%);
}

[type="range"]::-moz-range-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.2em;
    background: rgb(255 255 255 / 19%);
}

[type="range"]::-ms-track {
    box-sizing: border-box;
    border: none;
    width: 12.5em;
    height: 0.2em;
    background: rgb(255 255 255 / 19%);
}

[type="range"]::-moz-range-progress {
    height: 0.2em;
    background: #eee;
}

[type="range"]::-ms-fill-lower {
    height: 0.2em;
    background: #eee;
}

[type="range"]::-webkit-slider-thumb {
    margin-top: -0.65em;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 6px solid var(--primary);
    transform: rotate(-45deg);
    background: var(--primary);
    cursor: pointer;
}

[type="range"]::-webkit-slider-thumb:hover {
    border: 3px solid var(--primary);
}

[type="range"]::-moz-range-thumb {
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 6px solid var(--primary);
    transform: rotate(-45deg);
    background: var(--primary);
    cursor: pointer;
}

[type="range"]::-moz-range-thumb:hover {
    border: 3px solid var(--primary);
}

[type="range"]::-ms-thumb {
    margin-top: 0;
    box-sizing: border-box;
    border: none;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 6px solid var(--primary);
    transform: rotate(-45deg);
    background: var(--primary);
    cursor: pointer;
}

[type="range"]::-ms-thumb:hover {
    border: 3px solid var(--primary);
}

[type="range"]::-ms-tooltip {
    display: none;
}

[type="range"]~output {
    display: none;
}

.js [type="range"]~output {
    display: block;
    position: relative;
    left: 17px;
    top: -5px;
    transform: translate(calc((var(--val) - var(--min)) / (var(--max) - var(--min)) * 35em - 100%));
    color: var(--primary);
}

.js [type="range"]~output::before {
    content: "";
    display: block;
    width: 40px;
    height: 22px;
    background: #d9d9d9;
    border-radius: 5px;
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.calcu-header h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    color: var(--white);
}

.calcu-header h1 span {
    color: var(--primary);
}

.calcu-header p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: var(--white);
}

.slider-item h6 {
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    color: var(--white);
    /* text-transform: capitalize; */
    font-family: var(--AvenirNormal);
}

.annual-saving {
    border: 2px solid var(--primary);
    border-radius: 30px;
    padding: 45px 25px;
    color: var(--white);
    height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.annual-saving .row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: calc(100% - 230px);
}

.annual-saving h4 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.11) !important;
    opacity: 0.8;
    font-weight: 900;
    font-size: 24px;
}
[data-theme = 'light'] .annual-saving h4{
    border-bottom: 1px solid rgba(0, 0, 0, 0.11) !important;
}
[data-theme = 'light'] .borderend::before{
background-color: rgba(0, 0, 0, 0.11) !important;
}
.calcu-header {
    margin-bottom: 40px;
}

.f-12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--contentcolor);
    font-weight: normal;
}

.f-50 {
    font-size: 50px;
    line-height: normal;
    text-align: center;
    color: var(--primary);
    font-family: var(--AvenirNormal);
}

.f-34 {
    font-size: 34px;
    line-height: 52px;
    text-align: center;
    color: var(--primary);
    font-family: var(--AvenirNormal);
}

.border-end {
    border-right: 1px solid rgba(255, 255, 255, 0.11) !important;
}

.border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.11) !important;
}

a.calculate-btn {
    /* background: var(--primary);
    border-radius: 10px;
    width: 257px;
    max-width: 100%;
    padding: 10px 0;
    display: block;
    color: var(--white);
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 30px auto 0; */
}

/* The radio */

.radio {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--white);
    line-height: 24px;
    margin: 0;
}

/* Hide the browser's default radio button */

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.input-control {
    border: 1px solid var(--primary);
    border-radius: 10px;
    padding: 10px 15px;
    color: var(--white);
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: initial;
    min-width: 90px;
    width: 90px;
    text-align: center;
}

/* Create a custom radio button */

.checkround {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-color: var(--primary);
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
}

/* When the radio button is checked, add a blue background */

.radio input:checked~.checkround {
    background-color: transparent;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkround:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio input:checked~.checkround:after {
    display: block;
}

/* Style the indicator (dot/circle) */

.radio .checkround:after {
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--primary);
}

.border_outer {
    border: 1px solid #bdd5cc;
    padding: 27.5px 19.5px;
}

.radio_btn li {
    margin-right: 40px;
}

.radio_btn li:last-child {
    margin-right: 0;
}

.f-20.text-white {
    color: var(--white) !important;
    font-weight: normal !important;
    font-size: 20px;
}

.t-20 {
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    color: var(--white);
    opacity: 0.5;
}

.borderend::before {
    position: absolute;
    content: "";
    width: 1px;
    height: 130%;
    right: 0;
    background-color: rgba(255, 255, 255, 0.11);
    top: -15%;
    bottom: 0;
}

.borderend {
    position: relative;
}

@media(max-width: 1024.98px) {

    .f-20.text-white,
    .radio,
    .slider-item h6 {
        font-size: 18px;
        line-height: normal;
        margin-bottom: 0;
    }

    .annual-saving h4 {
        font-size: 18px;
        line-height: 28px;
    }

    .calcu-header h1 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 15px;
    }

    .calcu-wrapper {
        padding: 40px 30px;
    }

    .f-50 {
        font-size: 40px;
    }

    .annual-saving {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .annual-saving .btn--primary {
        padding: 16px;
    }
    .annual-saving .row {
        height: auto;
    }
    .calcu-wrapper {
        border-radius: 20px;
    }
    .annual-saving {}
}

@media(max-width: 767.98px) {
    #output{
        padding: 4px 13px;
        font-size: 12px;
    }
    .input-control{
        margin-top: 10px;
        padding: 8px 9px;
    }
    .f-20.text-white,
    .radio,
    .slider-item h6 {
        font-size: 16px;

    }

    .f-50 {
        font-size: 30px;
    }

    .calcu-header h1 {
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 15px;
    }

    .calcu-header p {
        font-size: 14px;
        line-height: 22px;
    }

    .calcu-wrapper {
        padding: 20px;
    }

    .annual-saving .border-end {
        border: 0 !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.11) !important;
    }

    .slider-item h6 {
        font-size: 16px;
        line-height: 20px;
    }


    .annual-saving .f-50 {
        font-size: 32px;
    }

    .annual-saving {
        padding-bottom: 25px;
    }

    
}

.calcu-list img {
    left: -16px;
    top: 0;
    width: 22px;
}