.teammembers .advisory .clickcustomform .team {
    background: #22242D;
    border-radius: 6.3704px;

}

.teammembers .advisory .clickcustomform .team-des {
    padding: 30px 15px;
    position: relative;
    height: 100%;
    min-height: 180px;   
    border-radius: 6px;
    transition: all ease-in-out 0.5s;
}
.teammembers .advisory .clickcustomform .team-des:hover {
    background: #ff7900;
    transform: scale(1.02);
    transition: all ease-in-out 0.5s;
}
.teammembers .advisory .clickcustomform .team-des h3 {
    margin-bottom: 15px;
}

.advisoryblock {
    margin-bottom: 20px;

}
@media (max-width: 767px) {
    .teammembers .advisory .clickcustomform .team-des{
        min-height: auto;
    }
    .advisoryblock:last-child{
        margin-bottom: 0;
    }
}