
.chargeitup{
    background: rgba(34, 36, 45, 0.2);
}
.chargeitup .image-out {
    width: calc(100% - 60%);
    padding: 0;
    right: 5%;
    left: inherit;
    /* position: relative; */
}
[data-theme= 'light'] .build-pump.chargeitup .home-banner-texter h1, [data-theme= 'light'] .build-pump.chargeitup .home-banner-texter p{
    color: #000 !important;
}
[data-theme='light'] .build-pump.chargeitup::before {
    z-index: 1;
    /* opacity: 0.2; */
}
[data-theme='light'] .inner-banner.chargeitup {
    /* background: #fff !important; */
}
[data-theme='light'] .inner-banner.chargeitup{
    background: rgba(210, 210, 210, 0.2);
}
[data-theme='light'] .chargeitup .home-banner-texter p{
    color: #2d2d2d;
}
.chargeitup::before {
    /* position: absolute;
    content: "";
    background: url(../assets/images/chargeitupbannerbg.svg) no-repeat;
    left: 50%;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    background-size: cover;
    background-position: center; */
}
.button-green .btn--primary, .button-green .btn--primary:hover{
background-color: var(--secondary);
border-color: var(--secondary);
}
.chargeitup h1{
font-size: 35px;
}
.chargeitup h1 span{
font-size: 37px;
color: var(--secondary);
}
.chargeitup i{
    margin-top: 40px;
    display: block;
}
.video-drop .video_block{
    filter: drop-shadow(21px 21px 0px rgba(34, 36, 45, 1));
}
.video-drop::before{
    right: -20px;
    bottom: -55px;
}
@media (min-width: 1440px) {
    .chargeitup i{
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        text-align: center;
        margin: auto;
    }
}
@media (max-width: 1440px) {
    /* .chargeitup h1 span {
        font-size: 85px;
    }
    .chargeitup h1 {
        font-size: 110px;
        line-height: 65px;
    } */
}
@media (max-width: 1366px) {
    /* .chargeitup h1 span {
        font-size: 70px;
    }
    .chargeitup h1 {
        font-size: 90px;
        line-height: 45px;
    } */
}
@media (max-width: 991px) {
    .chargeitup p{
        margin-top: -20px;
    }
    .chargeitup .image-out {
        width: calc(100% - 20%);
    }
    /* .chargeitup h1 span {
        font-size: 65px;
    }
    .chargeitup h1 {
        font-size: 80px;
        line-height: 40px;
    } */
    .chargeitup::before {
        z-index: -1;
        background-size: cover;
        background-position: top;
        width: 100%;
        margin: auto;
        left: 0;
    }
    .video-drop::before{
        right: 0;
        bottom: -34px;
    }
    .video-drop .video_block{
        filter: drop-shadow(15px 15px 0px rgba(34, 36, 45, 1));
    }
    .chargeitup.section-banner .container{
padding: 0 !important;
    }
}
@media (max-width: 575px) {
    .chargeitup h1 {
        font-size: 38px;
        line-height: 38px;
    }
    .chargeitup h1 span {
        font-size: 38px;
        line-height: 60px;
    }
    .chargeitup i img{
        width: 70px;
    }
    .chargeitup i{
        margin-top: 10px;
    }
    .chargeitup.inner-banner .home-banner-texter{
        padding: 20px 0 0;
    }
}
@media (max-width: 420px) {
    .chargeitup h1 {
        font-size: 29px;
        line-height: 38px;
    }
    .chargeitup h1 span {
        font-size: 29px;
        line-height: 60px;
    }
}