.map-container {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    overflow: hidden;
}

.mapheight {
    height: 500px;
}

.map-container iframe {
    min-height: 200px;
}

.fueling-future .heading-style h5 {
    font-family: var(--FuturaBold);
    margin-bottom: 22px;
}

.fueling-future .heading-style h4 {
    font-family: var(--AvenirNormal);
    letter-spacing: 0.1rem;
}

[data-theme = 'light'] .fueling-future {
    border: 2px solid var(--lightestgreyborder);
}
.fueling-future {
    padding: 24px;
    border-radius: 50px;
    border: 1px solid #FF7900;
}

.fueling-future .heading-style {
    margin-bottom: 25px;
}

.icon-identifi ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-identifi li {
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    margin-right: 25px;
    letter-spacing: 0.01em;
    font-size: 16px;
    font-family: var(--AvenirHeavy);   cursor: pointer;
}

.icon-identifi li:last-child {
    margin-right: 0;
}

.icon-identifi li:first-child:before {
    background-color: #ffffff !important;
}
[data-theme = 'light'] .icon-identifi li:first-child:before {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.icon-identifi li:nth-child(2):before {
    background-color: #542581 !important;
}

.icon-identifi li:nth-child(3):before {
    background-color: var(--primary);
}

.icon-identifi li:last-child:before {
    background-color: var(--secondary);
}

.icon-identifi li::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    background-color: var(--primary);
    left: 0;
}

@media (max-width: 767px) {
    .fueling-future {
        padding: 14px;
        border-radius: 20px;
    }

    .map-container {
        border-radius: 20px;
    }

    .icon-identifi ul {
        flex-wrap: wrap;
    }

    .icon-identifi li {
        font-size: 14px;
        width: 30%;
        margin-right: 0px;
    }

    .mapheight {
        height: 350px;
    }
}

@media (max-width: 390px) {
    .icon-identifi li {
        width: 43%;
    }
}