.cuitab .slider-thumb span {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--FuturaBold);
    color: var(--white);
    height: 25px;
}

.cuitab,
.bggrey {
    position: relative;
}

.bggrey::before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 12%;
    bottom: 0;
    transform: translate(0%, 0);
    background: #22242D;
    opacity: 0.6;
}

[data-theme='light'] .bggrey::before {
    background-color: #f1f3f3;
}

.slick-slide.slick-current .tabicon figcaption {
    background: #00A651;
    border: 1.5px solid transparent;
}
.slick-slide.slick-current .tabicon figcaption span{
color: #ffffff;
}
.tabicon figcaption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 45px;
    border: 1.5px solid #22242D;
    border-radius: 10px;
}

.tabicon figcaption img {
    height: 50px;
    display: block;
    object-fit: contain;

}
[data-theme = 'light'] .tabicon figcaption img{
    filter: invert(1) brightness(20.5);
}
.cuitab .slider-thumb.slick-slider {
    padding: 15px;
}

.tabicon {
    padding: 25px;
    cursor: pointer;
}

.slider-content .slick-list {
    /* overflow: inherit; */
}

.slider-content h6 {
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #FFFFFF;
    font-family: var(--AvenirNormal);
    opacity: 0.8;
}

.slider-content .slideinfo {
    padding: 0 70px;
}

.slick-slide.slick-current .tabicon figcaption img {
    filter: invert(1) brightness(10.5);
}

.slick-slide.slick-current .tabicon figcaption i {
    background-color: #02B559;
}

.slick-slide .tabicon figcaption i {
    height: 120px;
    width: 120px;
    background-color: #22242D;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
}

[data-theme='light'] .slick-slide.slick-current .tabicon figcaption i {
    background-color: #02B559;
}

[data-theme='light'] .slick-slide .tabicon figcaption i{
    background-color: #7a7b81;
}
[data-theme='light'] .slidercontenticon i {
    background-color: #e3e3e3;
}

.slidercontenticon figcaption {
    text-align: center;
    position: relative;
}

.slidercontenticon i {
    width: 100%;
    height: 170px;
    background-color: #22242D;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.slidercontenticon i img {
    transition: all ease-in-out 0.5s;
}
[data-theme = 'light'] .slidercontenticon i img{
    /* filter: invert(1) brightness(20.5); */
}
.slidercontenticon figcaption:hover img {
    transform: scale(1.1);
    transition: all ease-in-out 0.5s;
}

.slidercontenticon span {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    font-family: var(--FuturaBold);
    color: var(--white);
}

.cuitab .slick-prev:hover,
.cuitab .slick-next:hover {
    background-color: var(--secondary) !important;
}

.cuitab .slick-prev,
.cuitab .slick-next {
    top: 30%;
}

.bggrey .cuitab::before {
    position: absolute;
    content: "";
    /* background-color: #22242D; */
    border: 45px solid rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    left: -8%;
    top: 82%;
    bottom: 0%;
    width: 297px;
    height: 302px;
}

.bggrey .cuitab::after {
    position: absolute;
    content: "";
    /* background-color: #22242D; */
    border: 25px solid rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    right: -6%;
    top: 60%;
    width: 200px;
    height: 200px;
}
[data-theme = 'light'] .bggrey .cuitab::before{
    border: 45px solid rgba(0, 0, 0, 0.05);
}
[data-theme = 'light'] .bggrey .cuitab::after{
    border: 25px solid rgba(0, 0, 0, 0.05);
}
.bggrey .cuitab {
    position: relative;
    overflow: hidden;
}
@media (max-width: 1024px) {
    .cuitab .slider-thumb .slick-prev, .cuitab .slider-thumb .slick-next{
top: 50%;
    }
}
@media (max-width: 767px) {

    .slider-content .slideinfo{
        padding: 0;
    }
    .bggrey .cuitab::after{
        right: -46%;
    }
    .bggrey .cuitab::before{
        left: -68%;
        top: 92%;
    }
    .slidercontenticon figcaption{
        margin-bottom: 25px;
    }
}